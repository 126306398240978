import styled from 'styled-components';

import * as svars from 'style/variables';

const ICON_DURATION = '3.0s';
const GLASS_DURATION = '1.5s';

const illustrationSizePx = 100;
const illustrationPaddingPx = illustrationSizePx / 10;
const magnifyingGlassDiameterPx = (7 / 9) * illustrationSizePx;
const magnifyingGlassShadowDiameter = 0.8 * magnifyingGlassDiameterPx;
const handleHeight = (3 * magnifyingGlassDiameterPx) / 40;

const StyledDiv = styled.div`
  margin-bottom: ${svars.spaceMedium};
  height: ${illustrationSizePx}px;
  width: ${magnifyingGlassDiameterPx + 2.5 * handleHeight}px;
  display: inline-flex;

  & .glass-container {
    position: relative;
    height: ${magnifyingGlassDiameterPx + illustrationPaddingPx}px;
    display: box;
    display: flex;
    box-pack: justify;
    justify-content: space-between;
    box-align: center;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      top: ${illustrationSizePx + illustrationPaddingPx}px;
      left: calc((100% - ${magnifyingGlassShadowDiameter}px) / 2);
      width: ${magnifyingGlassShadowDiameter}px;
      height: ${1.5 * illustrationPaddingPx}px;
      border-radius: 100%;
      background-color: rgba(222, 225, 228, 0.4);
      animation-name: shadow-loop;
      animation-duration: ${GLASS_DURATION};
      animation-iteration-count: infinite;
      animation-timing-function: ease;
      animation-direction: alternate;
    }
  }

  // glass interior
  & .magnifier {
    position: relative;
    top: 0px;
    left: 0px;
    display: box;
    display: flex;
    box-pack: center;
    justify-content: center;
    box-align: center;
    align-items: center;
    width: ${magnifyingGlassDiameterPx}px;
    height: ${magnifyingGlassDiameterPx}px;
    border-radius: 100%;
    background-color: transparent;
    border: ${0.05 * magnifyingGlassDiameterPx}px solid ${svars.colorGrey};
    overflow: hidden;
  }

  & .wrap {
    display: box;
    display: flex;
    box-pack: justify;
    justify-content: space-between;
    box-orient: horizontal;
    box-direction: normal;
    flex-direction: row;
  }

  & .handle {
    position: relative;
    left: ${0.75 * magnifyingGlassDiameterPx}px;
    top: ${0.85 * magnifyingGlassDiameterPx}px;
    animation-name: cloud-loop;
    animation-duration: ${GLASS_DURATION};
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    animation-direction: alternate;

    &::before {
      content: '';
      position: absolute;
      left: ${magnifyingGlassDiameterPx / 6}px;
      top: ${magnifyingGlassDiameterPx / 20}px;
      width: ${magnifyingGlassDiameterPx / 8}px;
      height: ${magnifyingGlassDiameterPx / 20}px;
      background-color: ${svars.colorGrey};
      transform: rotate(45deg);
    }

    &::after {
      content: '';
      position: absolute;
      left: ${magnifyingGlassDiameterPx / 5}px;
      top: ${magnifyingGlassDiameterPx / 8}px;
      width: ${(9 * magnifyingGlassDiameterPx) / 40}px;
      height: ${handleHeight}px;
      background-color: ${svars.colorGrey};
      transform: rotate(45deg);
      border-radius: 0 4px 4px 0;
    }
  }

  & .box-1 {
    display: box;
    display: flex;
    box-pack: justify;
    justify-content: space-between;
  }

  & .box-2 {
    display: box;
    display: flex;
    box-pack: justify;
    justify-content: space-between;
  }

  @keyframes rowup-1 {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(50%, 0, 0);
    }
  }

  @keyframes rowup-2 {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(50%, 0, 0);
    }
  }

  @keyframes cloud-loop {
    0% {
      transform: translate(0, ${illustrationPaddingPx}px);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes shadow-loop {
    0% {
      transform: translate(0, -${illustrationSizePx / 5}px) scale(1.15, 0.25);
    }
    100% {
      transform: translate(0, -${illustrationSizePx / 5}px) scale(1, 0.25);
    }
  }

  & .box-1 {
    animation: ${ICON_DURATION} rowup-1 linear infinite normal;
  }

  & .box-2 {
    animation: ${ICON_DURATION} rowup-2 linear infinite normal;
  }

  & .magnifier {
    animation-name: cloud-loop;
    animation-duration: ${GLASS_DURATION};
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    animation-direction: alternate;
  }
`;

const StyledIconContainer = styled.span`
  font-size: ${illustrationSizePx / 3}px;
  margin: ${illustrationSizePx / 10}px;
  display: flex;
  align-items: center;
`;

const MagnifyingGlass = ({ icon }) => {
  return (
    <StyledDiv>
      <div className="handle"></div>
      <div className="glass-container">
        <div className="magnifier">
          <div className="wrap">
            <div className="box-1 box">
              <StyledIconContainer>{icon}</StyledIconContainer>
              <StyledIconContainer>{icon}</StyledIconContainer>
            </div>
            <div className="box-2 box">
              <StyledIconContainer>{icon}</StyledIconContainer>
              <StyledIconContainer>{icon}</StyledIconContainer>
            </div>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

export default MagnifyingGlass;
