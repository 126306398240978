import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { api } from 'app/api';
import * as Sentry from '@sentry/react';
import feedbackCampaignService, {
  identificationFormTypes,
} from 'services/feedbackCampaign.service';
import ontologyService from 'services/ontology.service';
import { getAllSearchParams } from 'helpers';

// Geo info
// Deactivated for now as it is not GDPR compliant
// const clientInfoFields = [
//   'asn',
//   'city',
//   'continent_code',
//   'country',
//   'country_name',
//   'ip',
//   'latitude',
//   'longitude',
//   'postal',
//   'region',
//   'region_code',
// ];
// export const getGeoInfoAsync = createAsyncThunk('client/info', async () => {
//   let data;
//   try {
//     const response = await axios.get('https://ipapi.co/json/');
//     data = {};
//     clientInfoFields.forEach((field) => {
//       data[field] = response.data[field];
//     });
//   } catch (error) {
//     Sentry.captureException(error);
//   }
//   return data;
// });

const initialState = {
  client: {},
  text: '',
  sentimentRangeValue: null,
  anonymous: false,
  loading: false,
  published: false,
  identification: {},
  // Keep previously published review id to identify later on
  publishedReviewConceptIds: [],
  email: '',
  identified: false,
  identifyError: false,
  preview: false,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(publishReview(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const publishReview = createAsyncThunk(
  'review/publish',
  async ({ searchQuery, conceptId }, { getState }) => {
    const { text, sentimentRangeValue } = getState().review;

    const { identification } = getState().review;
    // Comment up backend calls for now
    const { identificationForm } = feedbackCampaignService;
    const payload = {
      text: text || null,
      concept: {
        id: conceptId,
      },
      date: new Date().toJSON(),
      query: getAllSearchParams(searchQuery),
    };
    if (sentimentRangeValue) {
      payload.satisfaction_tag = { id: sentimentRangeValue };
    }
    payload.respondent_values = Object.entries(identification)
      .filter(([, value]) => value !== null)
      .map(([formId, value]) => {
        const identificationMetaData = identificationForm.find(
          ({ id }) => id === formId
        );
        const form_element = {
          id: identificationMetaData.id,
          form_type: identificationMetaData.type,
        };
        switch (form_element.form_type) {
          case identificationFormTypes.MULTIPLE_CHOICE: {
            return {
              form_element,
              tag: { id: value },
              tag_set: { id: identificationMetaData.tagSetId },
            };
          }
          case identificationFormTypes.INPUT: {
            return {
              form_element,
              value,
            };
          }
          default: {
            return {};
          }
        }
      });
    let response;
    try {
      response = await api.post('feedback', payload);
    } catch (error) {
      Sentry.captureException(error);
    }
    return response ? response.data : response;
  }
);

const resetFeedbackFromState = (state) => {
  state.sentimentRangeValue = initialState.sentimentRangeValue;
  state.text = initialState.text;
  state.published = initialState.published;
  state.error = initialState.error;
  return state;
};

export const reviewSlice = createSlice({
  name: 'feedback',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setText: (state, { payload }) => {
      state.text = payload;
    },
    setIdentification: (state, { payload: { id, value } }) => {
      state.identification[id] = value;
    },
    setSentimentRangeValue: (state, { payload }) => {
      state.sentimentRangeValue = payload;
    },
    toggleAnonymous: (state) => {
      state.anonymous = !state.anonymous;
    },
    resetFeedback: (state) => {
      resetFeedbackFromState(state);
    },
    setPreviewMode: (state, { payload }) => {
      state.preview = payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(publishReview.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.identified = false;
      })
      .addCase(publishReview.fulfilled, (state, action) => {
        if (action.payload === 'Review not sent') {
          state.preview = true;
        } else {
          state.publishedReviewConceptIds.push(action.payload.concept_id);
        }
        state.loading = false;
        state.published = true;
        resetFeedbackFromState(state);
      })
      .addCase(publishReview.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
  },
});

export const {
  setText,
  setSentimentRangeValue,
  toggleAnonymous,
  resetFeedback,
  setIdentification,
  setPreviewMode,
} = reviewSlice.actions;

// Selectors
export const selectText = (state) => state.review.text;
export const selectSentimentRangeValue = (state) =>
  state.review.sentimentRangeValue;
export const selectAnonymous = (state) => state.review.anonymous;
export const selectLoading = (state) => state.review.loading;
export const selectPublished = (state) => state.review.published;
export const selectError = (state) => state.review.error;
export const selectIdentified = (state) => state.review.identified;
export const selectEmail = (state) => state.review.email;
export const selectPublishedReviewConceptIds = (state) =>
  state.review.publishedReviewConceptIds;
export const selectIdentification = (state) => state.review.identification;
export const selectPreview = (state) => state.review.preview;

export const selectPublishDisabled = createSelector(
  selectText,
  selectSentimentRangeValue,
  selectIdentification,
  (text, sentimentRangeValue, identification) => {
    const { identificationForm } = feedbackCampaignService;
    const mandatoryIdentificationForm = identificationForm.filter(
      ({ mandatory }) => mandatory
    );
    return (
      !(text || sentimentRangeValue) ||
      mandatoryIdentificationForm.some(({ id }) => !identification[id]?.length)
    );
  }
);
export const selectRemainingHighlightConceptsItems = createSelector(
  selectPublishedReviewConceptIds,
  (publishedReviewConceptIds) => {
    const { highlightConcepts } = ontologyService;
    return highlightConcepts
      .filter(
        ({ id }) =>
          !(publishedReviewConceptIds && publishedReviewConceptIds.includes(id))
      )
      .map(({ id, name }) => ({
        key: id,
        value: id,
        label: name,
      }));
  }
);

export default reviewSlice.reducer;
