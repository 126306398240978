import styled from 'styled-components';

import { withAnalytics } from 'app/analytics';
import * as svars from 'style/variables';
import { lighten } from 'polished';

const BaseTag = styled.button`
  outline: none;
  padding: ${svars.spaceSmall} ${svars.spaceNormalLarge};
  font-weight: ${svars.fontWeightMedium};
  font-size: ${svars.fontSizeBase};
  border: 1px solid ${({ theme }) => theme.tag.borderColor || 'transparent'};
  box-shadow: ${({ theme }) => theme.tag.boxShadow};
  cursor: pointer;
  transition: ${svars.transitionBase};
  background: ${({ theme }) => theme.tag.background};
  color: ${({ theme }) => theme.fontColorBase};
  border-radius: ${({ theme }) => theme.tag.borderRadius};
  transition: ${({ theme }) => theme.tag.transition};

  &:hover {
    background: ${({ theme, disabled }) =>
      disabled
        ? lighten(0.05, theme.tag.background)
        : theme.tag.hover.background};
    border-color: ${({ theme }) =>
      theme.tag.hover.borderColor || theme.tag.borderColor || 'transparent'};
  }
`;

BaseTag.defaultProps = {};

const AnalyticsTag = withAnalytics(BaseTag);
export default AnalyticsTag;
