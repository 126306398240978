import { useCallback } from 'react';
import { BiHelpCircle } from 'react-icons/bi';
import { FaTimes, FaRegEdit } from 'react-icons/fa';
import { HiDotsHorizontal } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';

import { setIsOpen } from 'store/search';
import { toggleHelpModal } from 'store/ui';

import ConceptWall from 'components/ui/ConceptWall';
import Divider from 'components/ui/Divider';
import { H2, H4 } from 'components/ui/Header';
import MagnifyingGlass from 'components/ui/MagnifyingGlass';
import Search from 'components/ui/Search';
import Button from 'components/ui/clickable/Button';
import ClickableIcon from 'components/ui/clickable/ClickableIcon';
import Link from 'components/ui/clickable/Link';
import feedbackCampaignService from 'services/feedbackCampaign.service';
import * as svars from 'style/variables';
import { useNavigateToFeedback } from 'tools/hooks';
import { pushWithSearchQuery } from 'helpers';
import configurationUiService from 'services/configuration.service';

const ScrollableContainer = styled.div`
  overflow-y: auto;
  display: flex;
`;

const EmptySearchContainer = styled.div`
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${svars.spaceXLarge};
  padding-bottom: ${svars.spaceNormal};

  & header {
    max-width: 400px;
    padding: ${svars.spaceMedium};
  }
  & span {
    max-width: 500px;
  }
`;

const PageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

function EmptySearchMessage({ emptySearchQuery, goToGeneralFeedback }) {
  const {
    wording: { search },
  } = feedbackCampaignService;
  return (
    <ScrollableContainer>
      <EmptySearchContainer>
        <span style={{ display: 'flex', justifyContent: 'center' }}>
          <MagnifyingGlass icon={<SecondaryFaTimes />} />
        </span>
        <H2 centered>{search.noResults}</H2>
        <span>
          <ul>
            <li style={{ paddingBottom: svars.spaceNormal }}>
              <Link
                gaCategory="Search"
                gaAction="Empty search - reset search"
                onClick={emptySearchQuery}
              >
                {search.clearSearch}
              </Link>
              ;
            </li>
            <li style={{ paddingBottom: svars.spaceNormal }}>
              <Link
                gaCategory="Search"
                gaAction="General feedback (link)"
                onClick={goToGeneralFeedback}
              >
                {search.leaveQuickFeedback}
              </Link>
              .
            </li>
          </ul>
        </span>
      </EmptySearchContainer>
    </ScrollableContainer>
  );
}

EmptySearchMessage.propTypes = {
  emptySearchQuery: PropTypes.func.isRequired,
  goToGeneralFeedback: PropTypes.func.isRequired,
};

const SecondaryFaQuestion = styled(HiDotsHorizontal)`
  color: ${({ theme }) => theme.secondary || theme.primary};
`;

const SecondaryFaTimes = styled(FaTimes)`
  color: ${({ theme }) => theme.secondary || theme.primary};
`;

function InitialSearchMessage({ goToGeneralFeedback, keywordExamples }) {
  const { search } = feedbackCampaignService.wording;
  const {
    wording: { commons },
  } = configurationUiService;
  return (
    <ScrollableContainer>
      <EmptySearchContainer>
        <span style={{ display: 'flex', justifyContent: 'center' }}>
          <MagnifyingGlass icon={<SecondaryFaQuestion />} />
        </span>
        <H2 centered>{search.searchAConcept}</H2>
        <span>
          <div style={{ paddingBottom: svars.spaceNormal }}>
            {search.searchAConceptSubtitle}
          </div>
          {commons.exemples} :{' '}
          <ul style={{ margin: 0 }}>
            {keywordExamples.map((kw) => (
              <li key={`keyword-${kw}`}>{kw}</li>
            ))}
          </ul>
          <Divider centered padded />
          <Button
            gaCategory="Search"
            gaAction="General feedback (link)"
            secondary
            onClick={goToGeneralFeedback}
            style={{ margin: 'auto', display: 'flex' }}
          >
            <FaRegEdit style={{ paddingRight: svars.spaceMedium }} />{' '}
            {search.leaveQuickFeedback}
          </Button>
        </span>
      </EmptySearchContainer>
    </ScrollableContainer>
  );
}

InitialSearchMessage.propTypes = {
  goToGeneralFeedback: PropTypes.func.isRequired,
  keywordExamples: PropTypes.arrayOf(PropTypes.string),
};
InitialSearchMessage.defaultProps = { keywordExamples: [] };

const PageHeader = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: ${svars.spaceSmall} ${svars.spaceNormal};
`;
function FeedbackSearchPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleClick = useNavigateToFeedback();
  const dispatchToggleFaq = () => dispatch(toggleHelpModal());
  const { relaxed } = useTheme();

  const goToSearch = useCallback(
    (searchQuery) => {
      pushWithSearchQuery('/feedback', history, { query: searchQuery }, []);
      dispatch(setIsOpen(true));
    },
    [history, dispatch]
  );

  const {
    wording: { search },
  } = feedbackCampaignService;
  return (
    <PageContainer>
      <PageHeader>
        <H4 style={{ padding: 0 }} inline>
          {search.title}
        </H4>
        <ClickableIcon
          gaCategory="Search"
          gaAction="display faq"
          primary
          icon={<BiHelpCircle />}
          onClick={dispatchToggleFaq}
          data-testid="feedback-clickable-icon-faq"
        />
      </PageHeader>
      {relaxed ? (
        <ConceptWall selectConcept={handleClick} />
      ) : (
        <Search
          onClick={handleClick}
          emptySearchComponent={EmptySearchMessage}
          initialSearchComponent={InitialSearchMessage}
        >
          <ConceptWall selectConcept={handleClick} goToSearch={goToSearch} />
        </Search>
      )}
    </PageContainer>
  );
}

FeedbackSearchPage.propTypes = {};

export default FeedbackSearchPage;
