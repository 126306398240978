import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H5 } from 'components/ui/Header';
import * as svars from 'style/variables';

const Line = styled.div`
  width: ${({ centered }) => (centered ? 'auto' : '2vw')};
  opacity: 0.65;
  border-bottom: 1px solid
    ${({ theme, primary }) =>
      primary ? theme.primaryDisabled : theme.fontColorLightest};
  line-height: 0.1em;
  flex-grow: ${({ centered }) => (centered ? 1 : 0)};

  & + header {
    padding: 0 10px;
  }
`;
Line.defaultProps = { centered: true };

const DividerContainer = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding: ${({ padded }) => (padded ? `${svars.spaceMedium} 0` : 0)};
`;

const Divider = ({ label, primary, centered, padded, style, action }) => (
  <DividerContainer padded={padded} style={style}>
    <Line
      centered={centered}
      primary={primary}
      style={{ minWidth: '10px', width: !centered ? '2vw' : null }}
    />
    {label ? (
      <H5
        style={{
          whiteSpace: 'pre',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          width: 'auto',
        }}
      >
        {label}
      </H5>
    ) : null}
    {action}
    <Line primary={primary} />
  </DividerContainer>
);

Divider.propTypes = {
  primary: PropTypes.bool,
  centered: PropTypes.bool,
  padded: PropTypes.bool,
  action: PropTypes.node,
};
Divider.defaultProps = {
  primary: false,
  centered: false,
  action: null,
  padded: false,
};

export default Divider;
