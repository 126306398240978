import { useMemo } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import styled, { useTheme } from 'styled-components';

import {
  selectPublishedReviewConceptIds,
  selectRemainingHighlightConceptsItems,
} from 'store/review';

import Divider from 'components/ui/Divider';
import { H4 } from 'components/ui/Header';
import Button from 'components/ui/clickable/Button';
import TagList from 'components/ui/clickable/TagList';
import MultiChoice from 'components/ui/input/MultiChoice';
import feedbackCampaignService from 'services/feedbackCampaign.service';
import ontologyService from 'services/ontology.service';
import * as svars from 'style/variables';
import { useNavigateToFeedback } from 'tools/hooks';
import { pushWithSearchQuery } from 'helpers';

import Segment from './Segment';

const OtherLinksContainer = styled(Segment)`
  width: calc(100% - 2 * ${svars.spaceNormal});
  min-height: 40px;
  padding: ${svars.spaceMedium} ${svars.spaceNormal};
  margin: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

function ConceptSuggestions() {
  const history = useHistory();
  const location = useLocation();
  const { relaxed } = useTheme();

  const { published } = feedbackCampaignService.wording;
  const publishedReviewConceptIds = useSelector(
    selectPublishedReviewConceptIds
  );
  const remainingHighlightConceptsItems = useSelector(
    selectRemainingHighlightConceptsItems
  );
  const handleTagClick = useNavigateToFeedback();
  const suggestedConcepts = useMemo(
    () => ontologyService.getSuggestedConcepts(publishedReviewConceptIds),
    [publishedReviewConceptIds]
  );
  if (location.pathname !== '/published') {
    return null;
  }
  return (
    <OtherLinksContainer>
      {!remainingHighlightConceptsItems?.length ? (
        <div>
          <div style={{ paddingLeft: svars.spaceMedium }}>
            <H4>
              {suggestedConcepts?.length
                ? published.secondSubtitle
                : published.allConceptsClearedSubtitle}
            </H4>
          </div>
          {relaxed ? (
            <MultiChoice
              horizontal
              gaCategory="Published"
              gaAction="Selected tag"
              options={suggestedConcepts}
              onChange={handleTagClick}
            />
          ) : (
            <TagList
              gaCategory="Published"
              gaAction="Selected tag"
              tags={suggestedConcepts}
              onClick={handleTagClick}
            />
          )}
          <Divider centered padded />
        </div>
      ) : null}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          gaCategory="Search"
          gaAction="general feedback"
          secondary
          onClick={() => pushWithSearchQuery('/feedback', history)}
          style={{ margin: 'auto' }}
          data-testid="feedback-published-return-to-search"
        >
          <FaSearch style={{ paddingRight: svars.spaceMedium }} />{' '}
          {published.searchCtaText}
        </Button>
      </div>
    </OtherLinksContainer>
  );
}

ConceptSuggestions.propTypes = {};
export default ConceptSuggestions;
