import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as svars from 'style/variables';
import { useOnClickOutside } from 'tools/hooks';

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${svars.dimmerBackgroundColor};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: 0.3s ease-in;
  z-index: 2;

  & .modal-dialog {
    border-radius: ${({ theme }) => theme.card.borderRadius};
    display: flex;
    flex-direction: column;
    max-height: 90%;
    margin: ${svars.spaceLarge} auto;
    max-width: 680px;
    background: ${svars.colorWhite};
    box-shadow: ${({ theme }) => theme.fontLighterBoxShadow};
    border-radius: ${({ theme }) => theme.borderRadius};
    color: ${({ theme }) => theme.fontColorBase};
    text-align: justify;

    transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-50%)')};
    transition: transform 0.3s ease-out;
  }

  & .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: ${svars.spaceNormalLarge};
    background: ${({ theme }) => theme.card.headerBackground || theme.primary};
    color: ${({ theme }) => theme.fontColorPrimary};
    border-radius: ${svars.borderRadius} ${svars.borderRadius} 0 0;

    & .modal-title {
      text-align: left;
      display: inline-flex;
      align-items: center;
      margin: auto 0;
      font-size: ${svars.fontSizeMedium};

      & svg {
        font-size: ${svars.fontSizeLarge};
        padding-right: ${svars.spaceNormal};
      }
    }

    & .modal-close {
      font-size: ${svars.fontSizeXLarge};
      outline: none;
      padding: ${svars.spaceNormal};
      border: none;
      font-weight: 700;
      line-height: 1;
      color: ${({ theme }) => theme.fontColorPrimary};
      background: transparent;
      cursor: pointer;

      &:hover {
        color: ${({ theme }) => theme.fontColorPrimaryHover};
        opacity: 0.75;
      }
    }
  }

  & .modal-body {
    padding: ${svars.spaceNormal} 0;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }
`;
const ModalActionContainer = styled.div`
  text-align: end;
  padding: ${svars.spaceMedium} ${svars.spaceMedium} ${svars.spaceMedium} 0;
  border-top: 1px solid ${svars.colorGreyMedium};
  box-shadow: ${svars.baseBoxShadow};
  background: ${svars.colorWhiteDarker};
  border-radius: 0 0 ${svars.borderRadius} ${svars.borderRadius};
`;

const Modal = ({ header, icon, hide, isOpen, children, actions }) => {
  useEffect(() => {
    const close = (e) => {
      if (isOpen && e.key === 'Escape') {
        hide();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [hide, isOpen]);
  const ref = useRef();
  useOnClickOutside(ref, () => isOpen && hide());

  return (
    <ModalContainer open={isOpen}>
      <div
        className="modal-dialog"
        role="dialog"
        aria-labelledby="modal-label"
        aria-modal="true"
        ref={ref}
      >
        <div className="modal-header">
          <h5 id="modal-label" className="modal-title">
            {icon}
            {header}
          </h5>
          <button
            className="modal-close"
            onClick={hide}
            type="button"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">{children}</div>
        {actions ? (
          <ModalActionContainer>{actions}</ModalActionContainer>
        ) : null}
      </div>
    </ModalContainer>
  );
};

Modal.propTypes = {
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  // If provided, place icon on the left of the header
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
  actions: PropTypes.node,
  isOpen: PropTypes.bool,
  hide: PropTypes.func.isRequired,
};

Modal.defaultProps = { header: null, isOpen: false, actions: null, icon: null };

Modal.Action = ModalActionContainer;

export default Modal;
