import { useCallback, useState } from 'react';
import { FaRegLightbulb } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import styled, { useTheme } from 'styled-components';

import { selectRemainingHighlightConceptsItems } from 'store/review';

import { H4 } from 'components/ui/Header';
import TagList from 'components/ui/clickable/TagList';
import commonConfigurationService from 'services/configuration.service';
import feedbackCampaignService from 'services/feedbackCampaign.service';
import ontologyService from 'services/ontology.service';
import * as svars from 'style/variables';
import { useNavigateToFeedback } from 'tools/hooks';

import MultiChoice from './input/MultiChoice';

const Container = styled.div`
  margin: ${svars.spaceSmall} ${svars.spaceXSmall};
  padding: ${svars.spaceSmall} ${svars.spaceNormal};
  display: ${({ hidden }) => (hidden === '1' ? 'hidden' : 'flex')};
  flex-direction: column;
  background: ${({ theme }) => theme.primaryLightHover};
  border-radius: ${({ theme: { card } }) => card.borderRadius};
  box-shadow: ${svars.baseBoxShadow};
  align-self: auto;
`;

const TitleContainer = styled.a`
  user-select: none;
  display: inline-flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  color: ${({ theme }) => theme.primary};
  transition: ${svars.transitionBase};
  &:hover {
    color: ${({ theme }) => theme.primaryHovered};
  }
`;
const TitleTextContainer = styled(H4)`
  font-size: ${svars.fontSizeBase};
  padding-bottom: 0;
  margin-right: ${svars.spaceNormal};
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;
const SubtitleContainer = styled.div`
  margin-top: ${svars.spaceNormal};
  margin-bottom: ${svars.spaceSmall};
  font-size: ${svars.fontSizeSmall};
`;

const ArrowDown = styled(IoIosArrowDown)`
  margin: 0;
  font-size: ${svars.fontSizeLarge};
  transform: ${({ open }) => (!open ? 'rotate(180deg)' : null)};
`;

function ConceptHighlight() {
  const { pathname } = useLocation();
  const { relaxed } = useTheme();
  const [isOpen, setIsOpen] = useState(true);
  const toggleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const { conceptHighlight } = feedbackCampaignService.wording;
  const { commons } = commonConfigurationService.wording;
  const { highlightConcepts } = ontologyService;
  const remainingHighlightConceptsItems = useSelector(
    selectRemainingHighlightConceptsItems
  );
  const handleClick = useNavigateToFeedback();
  const nHighlights = highlightConcepts?.length;
  if (!nHighlights) {
    return null;
  }
  const nRespondedHighlights =
    nHighlights - remainingHighlightConceptsItems.length;
  // Hide component if pathname matches /feedback/<concept_id>...
  const hidden = !!pathname.match(/\/feedback\/\d+/);
  const completed = nRespondedHighlights === nHighlights;
  return (
    <Container hidden={hidden ? '1' : ''} open={isOpen ? '1' : ''}>
      <TitleContainer onClick={completed ? null : toggleOpen}>
        <TitleTextContainer>
          <FaRegLightbulb
            style={{
              marginLeft: '-3px',
              fontSize: svars.fontSizeXLarge,
              marginRight: svars.spaceSmall,
            }}
          />
          {conceptHighlight.title}
        </TitleTextContainer>
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              margin: `${svars.spaceSmall} 0`,
            }}
          >
            <span
              style={{ whiteSpace: 'nowrap', marginRight: svars.spaceSmall }}
            >
              {nRespondedHighlights} / {nHighlights}{' '}
            </span>
            <span
              style={{
                fontSize: svars.fontSizeXSmall,
                lineHeight: 0.8,
              }}
            >
              {commons.completed}
            </span>
          </span>
          {/* <ArrowDown open={isOpen} /> */}
          {!completed ? <ArrowDown open={isOpen} /> : null}
        </span>
      </TitleContainer>
      {isOpen ? (
        <span style={{ height: '100%', opacity: 1 }}>
          {remainingHighlightConceptsItems.length ? (
            <>
              <SubtitleContainer>{conceptHighlight.subTitle}</SubtitleContainer>
              <span>
                {relaxed ? (
                  <MultiChoice
                    options={remainingHighlightConceptsItems}
                    onChange={handleClick}
                    gaCategory="Select tag concept"
                    gaAction="Highlighted"
                    compact
                    horizontal
                  />
                ) : (
                  <TagList
                    tags={remainingHighlightConceptsItems}
                    gaCategory="Select tag concept"
                    gaAction="Highlighted"
                    onClick={handleClick}
                    alwaysExpanded
                  />
                )}
              </span>
            </>
          ) : (
            <SubtitleContainer>{conceptHighlight.noMore}</SubtitleContainer>
          )}
        </span>
      ) : null}
    </Container>
  );
}

ConceptHighlight.propTypes = {};
export default ConceptHighlight;
