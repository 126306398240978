import ontologyService from 'services/ontology.service';

/**
 * Python-like range method.
 *
 * @param {*} n the size of the range.
 */
export const range = (n, m = null) => {
  if (m !== null) {
    // n is the minimum value, m is max value plus one (because indexing starts at 0)
    return range(m - n).map((value) => value + n);
  }
  return Array(n)
    .fill()
    .map((_, i) => i);
};

const accentMap = {
  á: 'a',
  à: 'a',
  é: 'e',
  è: 'e',
  ê: 'e',
  ë: 'e',
  í: 'i',
  ï: 'i',
  î: 'i',
  ó: 'o',
  ô: 'o',
  ö: 'o',
  ú: 'u',
  û: 'u',
};

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function accentFold(s) {
  if (!s) {
    return '';
  }
  let ret = '';
  for (let i = 0; i < s.length; i += 1) {
    ret += accentMap[s.charAt(i)] || s.charAt(i);
  }
  return ret;
}

export const accentInsensitiveSearch = (
  options,
  query,
  valueAccessor = (row) => row.text
) => {
  const re = new RegExp(escapeRegExp(accentFold(query)), 'i');
  return options.filter((opt) => re.test(accentFold(valueAccessor(opt))));
};

const EmailRe =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (email) =>
  EmailRe.test(String(email).toLowerCase());

export const getRouteWithParams = (
  pathname,
  history,
  updateSearch = {},
  deleteFromSearch = ['query']
) => {
  let { search } = history.location;
  const nUpdates = Object.keys(updateSearch).length;
  if (nUpdates || deleteFromSearch.length) {
    const params = new URLSearchParams(search);
    if (deleteFromSearch.length) {
      deleteFromSearch.forEach((paramName) => params.delete(paramName));
    }
    if (nUpdates) {
      Object.entries(updateSearch).forEach(([key, value]) =>
        params.set(key, value)
      );
    }
    search = params.toString();
  }
  return { pathname, search };
};

export /**
 *
 *
 * @param {*} pathname if pathname includes general, we redirect to the parent concept
 * @param {*} history
 * @param {*} [updateSearch={}]
 * @param {string} [deleteFromSearch=['query']]
 */
const pushWithSearchQuery = (
  pathname,
  history,
  updateSearch = {},
  deleteFromSearch = ['query']
) => {
  // check if general slug is in path, if yes, redirect to the default concept
  const finalPath =
    (pathname.includes('general') &&
      ontologyService.rootConcept &&
      `/feedback/${ontologyService.rootConcept?.db_id}`) ||
    pathname;

  history.push(
    getRouteWithParams(finalPath, history, updateSearch, deleteFromSearch)
  );
};

export const getAllSearchParams = (searchQuery) => {
  const params = new URLSearchParams(searchQuery);
  let paramObj = {};
  for (var value of params.keys()) {
    paramObj[value] = params.get(value);
  }
  return paramObj;
};

/**
 *
 *
 * @export
 * @param {*} arr the array to get random elements from
 * @param {*} n: the maximum size of the result array
 * @return {*}
 */
export function getRandom(arr, nMax) {
  let len = arr.length;
  const taken = new Array(len);
  let n = Math.min(nMax, len);
  const result = new Array(n);
  while (n--) {
    const x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}
