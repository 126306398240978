import { useCallback } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import ChoiceButton from 'components/ui/clickable/ChoiceButton';
import * as svars from 'style/variables';
import RichTextRender from '../RichTextRender';

const MultiChoiceContainer = styled.div`
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  justify-content: ${({ horizontal }) =>
    horizontal ? 'flex-start' : 'center'};
  align-items: stretch;
  flex-wrap: wrap;
`;

const MultiChoice = ({
  options,
  selected,
  onChange,
  multiple,
  style,
  horizontal,
  compact,
  relaxed,
  collapsable,
  centered,
  onBlur,
  disabled,
  richText,
}) => {
  const onButtonClick = useCallback(
    (value) => (event) => {
      if (disabled) {
        return;
      }
      let newValue = value;
      if (multiple) {
        newValue = value;
        if (selected && selected.includes(value)) {
          newValue = selected.filter((item) => item !== value);
        } else if (selected && selected.length) {
          newValue = [...selected, value];
        } else {
          newValue = [value];
        }
      } else {
        if (selected === value) {
          newValue = null;
        }
      }
      onChange(newValue);
      // Remove focus from the button
      event.target?.blur();
      onBlur(event);
      event.preventDefault();
      event.stopPropagation();
    },
    [disabled, onChange, selected, multiple]
  );
  return (
    <MultiChoiceContainer
      horizontal={horizontal}
      style={style}
      data-testid="question-options"
    >
      {options.map(({ key, label, value, icon, secondary }, i) => {
        const isSelected = multiple
          ? selected?.includes(value)
          : selected === value;
        return (
          <ChoiceButton
            style={{
              marginBottom: svars.spaceNormal,
              flexGrow: relaxed ? 1 : 0,
              justifyContent:
                ((horizontal || centered) && 'center') || 'flex-start',
            }}
            horizontal={horizontal}
            disabled={disabled}
            onClick={onButtonClick(value)}
            selected={isSelected ? 'true' : null}
            key={key}
            icon={
              collapsable ? (
                <AiOutlineCloseCircle
                  size={svars.fontSizeLarge}
                  style={{ color: isSelected ? 'inherit' : 'transparent' }}
                />
              ) : (
                icon
              )
            }
            secondary={secondary}
            isOdd={!!(i % 2)}
            compact={compact}
            centered={centered}
            hidden={collapsable && !multiple && selected && !isSelected}
            choiceButtonTestId="fb-preview-pane-question-button"
          >
            {richText ? <RichTextRender content={label} /> : label}
          </ChoiceButton>
        );
      })}
    </MultiChoiceContainer>
  );
};

MultiChoice.onBlur = (event) => {
  event.target.blur();
};

MultiChoice.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.oneOf([null]).isRequired,
        PropTypes.any,
      ]),
      icon: PropTypes.node,
    })
  ).isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.any,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]),
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  horizontal: PropTypes.bool,
  // Compact buttons
  compact: PropTypes.bool,
  // Center text in buttons
  centered: PropTypes.bool,
  collapsable: PropTypes.bool,
  relaxed: PropTypes.bool,
  style: PropTypes.object,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  richText: PropTypes.bool,
};

MultiChoice.defaultProps = {
  selected: null,
  multiple: false,
  horizontal: false,
  compact: false,
  collapsable: false,
  onBlur: MultiChoice.onBlur,
  relaxed: false,
  centered: false,
  style: {},
  disabled: false,
  richText: false,
};

export default MultiChoice;
