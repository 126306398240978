import styled from 'styled-components';

import breakpoints from 'style/breakpoints';
import * as svars from 'style/variables';

const TextArea = styled.textarea`
  width: ${({ fluid }) => (fluid ? '98%' : 'initial')};
  margin: 0 auto;
  transition: ${svars.transitionBase};
  background: ${({ theme: { input } }) => input.background};
  color: ${({ disabled }) => (disabled ? svars.fontColorDisabled : 'inherit')};
  padding: ${svars.spaceNormal};
  border-radius: ${({ theme: { input } }) => input.borderRadius};
  border-color: ${({ theme: { input } }) => input.borderColor};
  resize: vertical;
  box-sizing: border-box;
  outline: none;

  @media only screen and (${breakpoints.device.sm}) {
    resize: none;
  }
  &:hover {
    border-color: ${({ theme: { input } }) => input.hover.borderColor};
    box-shadow: ${({ theme: { input } }) => input.hover.boxShadow};
  }

  &:focus-within {
    outline-color: transparent;
    backdrop-filter: blur(10px);
    border-color: ${({ theme: { input } }) => input.focus.borderColor};
    box-shadow: ${({ theme: { input } }) => input.focus.boxShadow};
  }
`;

TextArea.defaultProps = { disabled: false };

export default TextArea;
