import { useCallback } from 'react';
import { BiHelpCircle, BiLeftArrowAlt } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import styled from 'styled-components';

import { resetFeedback } from 'store/review';
import { toggleConfidentialityPolicy, toggleHelpModal } from 'store/ui';

import TextSentimentFeedback from 'page/feedback/TextSentimentFeedback';
import Divider from 'components/ui/Divider';
import { H4 } from 'components/ui/Header';
import FadeInOut from 'components/ui/animation/FadeInOut';
import ClickableIcon from 'components/ui/clickable/ClickableIcon';
import { getRouteWithParams, pushWithSearchQuery } from 'helpers';
import feedbackCampaignService from 'services/feedbackCampaign.service';
import ontologyService from 'services/ontology.service';
import * as svars from 'style/variables';

const FeedbackFormPageContainer = styled.div`
  height: 100%;
  overflow-y: hidden;
  display: flex;
  padding: 0;
  box-sizing: border-box;
`;

const FeedbackFormPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { category: conceptId } = useParams();
  const { feedback } = feedbackCampaignService.wording;
  const concept = ontologyService.getConcept(conceptId);
  const showFaq = useCallback(() => dispatch(toggleHelpModal()), [dispatch]);
  const showConfidentialityPolicy = useCallback(
    () => dispatch(toggleConfidentialityPolicy()),
    [dispatch]
  );

  if (!concept) {
    return <Redirect to={getRouteWithParams('/feedback', history)} />;
  }

  return (
    <FeedbackFormPageContainer>
      <FadeInOut
        show
        style={{
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: `${svars.spaceSmall} ${svars.spaceNormal}`,
          }}
          data-testid="feedback-header-slide"
        >
          <ClickableIcon
            gaCategory="Feedback"
            gaAction="back to search (arrow)"
            icon={<BiLeftArrowAlt />}
            primary
            onClick={() => {
              dispatch(resetFeedback());
              pushWithSearchQuery('/feedback', history);
            }}
            style={{ marginRight: svars.spaceNormal }}
          />
          <span style={{ flexGrow: 1 }}>
            {feedback.feedbackOn} :
            <H4 inline primary style={{ padding: `0 ${svars.spaceNormal}` }}>
              {concept?.name}
            </H4>
            <div
              style={{
                color: svars.fontColorLighter,
                fontSize: svars.fontSizeSmall,
              }}
            >
              {concept.description}
            </div>
          </span>
          <ClickableIcon
            gaCategory="Feedback"
            gaAction="display faq"
            style={{ float: 'right' }}
            primary
            icon={<BiHelpCircle />}
            onClick={showFaq}
          />
        </div>
        <Divider style={{ padding: `${svars.spaceSmall} 0 0 0` }} />
        <TextSentimentFeedback
          showConfidentilityPolicy={showConfidentialityPolicy}
        />
      </FadeInOut>
    </FeedbackFormPageContainer>
  );
};

export default FeedbackFormPage;
