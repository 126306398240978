import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const UNMOUNTED = 'unmounted';
const EXITED = 'exited';
const ENTERING = 'entering';
const ENTERED = 'entered';
const EXITING = 'exiting';

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

function FadeInOut({ show, duration, children, className, style }) {
  const [status, setStatus] = useState(show ? ENTERING : UNMOUNTED);

  useEffect(() => {
    let timeoutId;
    if (show) {
      setStatus(ENTERING);
      timeoutId = setTimeout(() => setStatus(ENTERED), 0);
    } else {
      setStatus(EXITING);
      timeoutId = setTimeout(() => setStatus(EXITED), duration);
    }
    return () => clearTimeout(timeoutId);
  }, [show, duration]);

  if (status === UNMOUNTED) {
    return null;
  }

  return (
    <div
      className={className}
      style={{
        ...style,
        transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0.1,
        overflow: 'hidden',
        ...transitionStyles[status],
      }}
    >
      {children}
    </div>
  );
}

FadeInOut.propTypes = {
  show: PropTypes.bool,
  duration: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};

FadeInOut.defaultProps = {
  show: false,
  duration: 350,
  children: null,
  className: '',
  style: {},
};

export default FadeInOut;
