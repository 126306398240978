import styled from 'styled-components';

import Link from 'components/ui/clickable/Link';
import logoSrc from 'public/logo.png';

import breakpoints from 'style/breakpoints';
import * as svars from 'style/variables';

const BwLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: ${svars.bwSiteLinkContainerHeight};
  font-size: ${svars.fontSizeSmall};
  text-align: center;
  margin-bottom: ${svars.spaceNormal};
  cursor: pointer;

  & a {
    border-radius: ${svars.borderRadius};
    text-decoration: none;
    background: ${({ theme }) => theme.card.background};
    padding: 6px 10px;
    border-radius: ${({ theme }) => theme.borderRadius};

    @media only screen and (${breakpoints.device.sm}) {
      font-size: ${svars.fontSizeSmall};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
  & img {
    height: ${svars.fontSizeBase};
    width: auto;
    margin-left: ${svars.spaceSmall};
  }

  @media only screen and (max-height: 400px) {
    display: none;
  }
`;

function BwCopyright() {
  return (
    <BwLogoContainer>
      <Link
        tabIndex={-1}
        gaCategory="Navigation"
        gaAction="Click BW logo"
        rel="noreferrer"
        target="_blank"
        href="https://www.better-world.io"
        translate="no"
      >
        Powered by Better World <img src={logoSrc} alt="Logo" />
      </Link>
    </BwLogoContainer>
  );
}

export default BwCopyright;
