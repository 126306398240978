import styled from 'styled-components';

import breakpoints from 'style/breakpoints';
import * as svars from 'style/variables';

const Segment = styled.div`
  transition: ${svars.transitionBase};
  box-shadow: ${svars.baseBoxShadow};
  // Margin to let box shadow be visible
  margin: ${svars.spaceXSmall};
  padding: 0;
  background: ${({ theme: { card } }) => card.background};
  border-radius: ${({ theme: { card } }) => card.borderRadius};
  border: 1px solid ${({ theme: { card } }) => card.borderColor};
  -webkit-backdrop-filter: blur(10px);
  */ @media only screen and (${breakpoints.device.sm}) {
    padding: ${svars.spaceMedium} ${svars.spaceNormal};
  }
`;

export default Segment;
