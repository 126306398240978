import styled from 'styled-components';

import * as svars from 'style/variables';

export const Tabs = styled.div`
  overflow: hidden;
  display: flex;
  width: 100%;
`;

export const Tab = styled.button`
  flex-grow: 1;
  padding-bottom: ${svars.spaceSmall};
  border: none;
  outline: none;
  cursor: pointer;
  line-height: 1.12;
  position: relative;
  font-weight: ${svars.fontWeightMedium};
  background-color: ${svars.colorWhite};
  opacity: ${({ active }) => (active ? 1 : '0.8')};
  color: ${({ theme, active }) => (active ? theme.primary : 'inherit')};
  border-bottom: 2px solid
    ${({ theme, active }) => (active ? theme.primary : svars.colorGreyLight)};

  & span {
    display: inline-flex;
    pointer-events: none;
    padding: 0 8px;
    padding-bottom: ${svars.spaceNormal};
  }

  &:hover {
    border-bottom-color: ${({ theme }) => theme.primary};
    & span {
    }
  }
`;
Tab.Content = styled.div`
  ${(props) => (props.active ? '' : 'display:none')}
`;
