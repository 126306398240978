import { createSlice } from '@reduxjs/toolkit';
import configurationUiService from 'services/configuration.service';

const initialState = {
  showHelpModalTabIndex: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    toggleHelpModal: (state) => {
      // If there are FAQ items, show the FAQ tab, otherwise show the confidentiality policy tab
      if (configurationUiService?.faqItems?.length > 0) {
        state.showHelpModalTabIndex = 1;
      } else {
        state.showHelpModalTabIndex = 2;
      }
    },
    toggleFaq: (state) => {
      state.showHelpModalTabIndex = 1;
    },
    toggleConfidentialityPolicy: (state) => {
      state.showHelpModalTabIndex = 2;
    },
    toggleUserTerms: (state) => {
      state.showHelpModalTabIndex = 3;
    },
    closeHelpModal: (state) => {
      state.showHelpModalTabIndex = null;
    },
  },
});

export const {
  toggleFaq,
  toggleHelpModal,
  toggleConfidentialityPolicy,
  toggleUserTerms,
  closeHelpModal,
} = uiSlice.actions;

// Selectors
export const selectShowHelpModalTabIndex = (state) =>
  state.ui.showHelpModalTabIndex;

export default uiSlice.reducer;
