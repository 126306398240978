import {
  FaAngry,
  FaFrown,
  FaGrinBeam,
  FaMeh,
  FaRegAngry,
  FaRegFrown,
  FaRegGrinBeam,
  FaRegMeh,
  FaRegSmile,
  FaSmile,
} from 'react-icons/fa';

import PropTypes from 'prop-types';

import ClickableIcon from 'components/ui/clickable/ClickableIcon';
import * as svars from 'style/variables';

const getEmoji = (value, checked) => {
  return (
    (value === -0.8 && [
      (checked && FaAngry) || FaRegAngry,
      svars.sentimentColors[0],
    ]) ||
    (value === -0.45 && [
      (checked && FaFrown) || FaRegFrown,
      svars.sentimentColors[1],
    ]) ||
    (value === 0.0001 && [
      (checked && FaMeh) || FaRegMeh,
      svars.sentimentColors[2],
    ]) ||
    (value === 0.45 && [
      (checked && FaSmile) || FaRegSmile,
      svars.sentimentColors[3],
    ]) ||
    (value === 0.8 && [
      (checked && FaGrinBeam) || FaRegGrinBeam,
      svars.sentimentColors[4],
    ])
  );
};

function ClickableEmoji({ id, value, onSelect, checked }) {
  const [EmojiComponent, color] = getEmoji(value, checked);
  return (
    <ClickableIcon
      onClick={() => onSelect(id)}
      style={{
        height: '100%',
        width: '100%',
        margin: 0,
        background: 'transparent',
        fontSize: checked ? '2.3em' : '2.1em',
        color: color,
        boxShadow: 'none',
      }}
      icon={
        <EmojiComponent
          style={{ borderRadius: '30px', background: 'rgba(255,255,255,0.5)' }}
        />
      }
      checked={checked}
      testid={`feedback-clickable-emoji-${value}`}
    />
  );
}

ClickableEmoji.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

ClickableEmoji.defaultProps = {
  checked: false,
};

export default ClickableEmoji;
