import PropTypes from 'prop-types';

import styled from 'styled-components';
import { H3 } from 'components/ui/Header';
import PartyPopper from 'components/ui/PartyPopper';
import RaisedHandSrc from 'public/raise-hand.png';
import * as svars from 'style/variables';
import { LinkButton } from 'components/ui/clickable/Button';

const PublishedPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${svars.spaceMedium};
  padding-bottom: ${svars.spaceLarge};
  overflow-y: hidden;
  height: 100%;
`;

const PublishedPageBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: ${svars.spaceLarge} ${svars.spaceMedium};
  max-width: ${svars.textMaxWidth};
`;

function EndScreen({
  title,
  description,
  ctaLabel,
  ctaUrl,
  withPopper,
  withRaiseHand,
}) {
  return (
    <PublishedPageContainer>
      {withPopper ? PartyPopper : null}
      {withRaiseHand ? (
        <img
          src={RaisedHandSrc}
          alt="🙌"
          style={{ width: `${svars.illustrationWidthRem}rem` }}
        />
      ) : null}
      <PublishedPageBody data-testid="fb-end-screen-published-page-body">
        <H3 data-testid="fb-end-screen-title" centered>
          {title}
        </H3>
        {description}
      </PublishedPageBody>
      {ctaLabel ? (
        <LinkButton
          gaCategory="Survey - published"
          gaAction="Click cta"
          href={ctaUrl}
          style={{ margin: `${svars.spaceMedium} 0` }}
          target="_blank"
        >
          {ctaLabel}
        </LinkButton>
      ) : null}
    </PublishedPageContainer>
  );
}

EndScreen.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  ctaLabel: PropTypes.string,
  ctaUrl: PropTypes.string,
  withPopper: PropTypes.bool,
  withRaiseHand: PropTypes.bool,
};

EndScreen.defaultProps = {
  ctaLabel: null,
  ctaUrl: null,
  withPopper: false,
  withRaiseHand: false,
};

export default EndScreen;
