import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectPreview, selectPublishedReviewConceptIds } from 'store/review';

import config from 'config';
import feedbackCampaignService from 'services/feedbackCampaign.service';
import { pushWithSearchQuery } from 'helpers';
import EndScreen from './ui/EndScreen';

const PublishedPage = () => {
  const history = useHistory();

  const publishedReviewConceptIds = useSelector(
    selectPublishedReviewConceptIds
  );
  const isPreview = useSelector(selectPreview);

  // If publishedReviewId is empty, redirect to home
  useEffect(() => {
    if (!publishedReviewConceptIds.length && !isPreview) {
      pushWithSearchQuery('/', history);
    }
  }, [publishedReviewConceptIds, isPreview, history]);

  // Generate next categories tags at render
  const { published } = feedbackCampaignService.wording;

  return (
    <EndScreen
      title={published.title}
      description={published.subtitle}
      ctaLabel={published.ctaLabel}
      withPopper={!config.ui.FEEDBACK_USE_RAISE_HAND}
      withRaiseHand={config.ui.FEEDBACK_USE_RAISE_HAND}
    />
  );
};

export default PublishedPage;
