import { BiHelpCircle } from 'react-icons/bi';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectSurveyHeaderTitle, selectProgress } from 'store/survey';
import ProgressBar from 'components/ui/ProgressBar';

import ClickableIcon from 'components/ui/clickable/ClickableIcon';
import { H4 } from 'components/ui/Header';
import * as svars from 'style/variables';

const HeaderContainer = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
`;

const TextHeader = styled(H4)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${svars.spaceSmall} ${svars.spaceMedium} 0 ${svars.spaceNormal};
  padding-bottom: ${svars.spaceSmall};
`;

function SurveyHeader({ showFaq }) {
  const surveyHeaderTitle = useSelector(selectSurveyHeaderTitle);
  const progress = useSelector(selectProgress);
  return (
    <HeaderContainer>
      <TextHeader>
        {surveyHeaderTitle}
        <ProgressBar
          progress={progress}
          style={{ marginTop: svars.spaceSmall }}
        />
      </TextHeader>
      <ClickableIcon
        gaCategory="Feedback"
        gaAction="display faq"
        style={{ float: 'right' }}
        primary
        icon={<BiHelpCircle />}
        onClick={showFaq}
      />
    </HeaderContainer>
  );
}

SurveyHeader.propTypes = {
  showFaq: PropTypes.func.isRequired,
};

export default SurveyHeader;
