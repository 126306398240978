import PropTypes from 'prop-types';
import { H5 } from 'components/ui/Header';
import Link from 'components/ui/clickable/Link';
import styled from 'styled-components';

import * as svars from 'style/variables';

const DisclaimerContainer = styled(H5)`
  padding: 0;
  padding: ${svars.spaceSmall} ${svars.spaceSmall} 0 0;
  text-align: inherit;
  line-height: 1.1;
`;

const PublishDisclaimer = ({
  showConfidentilityPolicy,
  privacyNoticePrefix,
  privacyPolicy,
}) => {
  return (
    <DisclaimerContainer italic info>
      {privacyNoticePrefix}{' '}
      <Link
        onClick={showConfidentilityPolicy}
        data-testid="feedback-form-privacy-link"
        style={{ whiteSpace: 'nowrap' }}
      >
        {privacyPolicy}
      </Link>
    </DisclaimerContainer>
  );
};

PublishDisclaimer.propTypes = {
  privacyNoticePrefix: PropTypes.string.isRequired,
  privacyPolicy: PropTypes.string.isRequired,
  showConfidentilityPolicy: PropTypes.func.isRequired,
};

export default PublishDisclaimer;
