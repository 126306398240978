import { FaRegEdit } from 'react-icons/fa';
import styled from 'styled-components';

import PropTypes from 'prop-types';

import Divider from 'components/ui/Divider';
import Button from 'components/ui/clickable/Button';
import TagWall from 'components/ui/input/TagWall';
import feedbackCampaignService from 'services/feedbackCampaign.service';
import ontologyService from 'services/ontology.service';
import * as svars from 'style/variables';

const ConceptWallContainer = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
function ConceptWall({ selectConcept, goToSearch }) {
  const {
    wording: { search },
  } = feedbackCampaignService;
  const { tagSectionOptions } = ontologyService;
  return (
    <ConceptWallContainer>
      <TagWall
        onClick={selectConcept}
        tagSectionOptions={tagSectionOptions}
        goToSearch={goToSearch}
        data-testid="feedback-tagwall-seearch"
      />
      <Divider centered padded style={{ paddingTop: svars.spaceNormal }} />
      <Button
        gaCategory="Search"
        gaAction="general feedback"
        secondary
        // general feedback receives a 'general' slug that will be redirected to the first parent concept
        onClick={() => selectConcept('general')}
        style={{ marginBottom: svars.spaceNormal }}
        data-testid="feedback-general-quick-feedback"
      >
        <FaRegEdit style={{ paddingRight: svars.spaceMedium }} />
        {search.leaveQuickFeedback}
      </Button>
    </ConceptWallContainer>
  );
}

ConceptWall.propTypes = {
  selectConcept: PropTypes.func.isRequired,
  goToSearch: PropTypes.func,
  initialSearchComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  focusOutComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};
ConceptWall.defaultProps = { focusOutComponent: null, goToSearch: null };

export default ConceptWall;
