import { useRef, useState } from 'react';
import { BsChevronUp } from 'react-icons/bs';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { withAnalytics } from 'app/analytics';
import { H4 } from 'components/ui/Header';
import * as svars from 'style/variables';
import RichTextRender from './RichTextRender';

const Container = styled.main`
  position: relative;
`;

const Section = styled.section`
  position: relative;
`;

const InnerSection = styled.div`
  position: relative;
`;

const AccordionContainer = styled.div``;

const AccordionItemContainer = styled.div`
  border-bottom: 1px solid ${svars.colorGreyMedium};
`;

const AccordionInner = styled.div`
  position: relative;
  width: 100%;

  & ${AccordionItemContainer}:last-child {
    border-bottom: none;
  }
`;

const AccordionTitle = styled(H4)`
  /* We set 92% instead of 95% as width to leave room for a possibly appearing scrolling bar */
  width: 92%;
  padding: ${svars.spaceLarge} 0 ${svars.spaceLarge} 5%;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  transition: ${svars.transitionBase};
  color: ${({ active, theme }) => (active ? theme.primary : 'inherit')};

  &:hover {
    color: ${({ theme }) => theme.primary};
  }

  & span {
    max-width: calc(100% - 15px);
  }

  & svg {
    position: absolute;
    right: ${svars.spaceLarge};
    font-size: ${svars.fontSizeMedium};
    transform: ${({ active }) => (active ? 'rotate(180deg)' : '')};
    transition: ${svars.transitionBase};
  }

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Transparent background on mobile touch */
  -webkit-tap-highlight-color: transparent;
`;

const AccordionBody = styled.div`
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  margin-bottom: ${svars.spaceSmall};
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s;

  ${({ active, bodyHeight }) =>
    active &&
    css`
      max-height: ${1.5 * bodyHeight}px;
    `}
`;

const AccordionContent = styled.div`
  margin: auto;
  padding: 0 ${svars.spaceLarge};
  vertical-align: middle;
  max-width: ${svars.textMaxWidth};
  margin-bottom: ${svars.spaceMedium};

  /* Remove top margin of first text element */
  & p:first-child {
    margin-top: 0;
  }
`;

const BaseAccordionItem = ({
  header,
  content,
  itemIndex,
  currentIndex,
  bodyHeight,
  setCurrentAccordion,
  setBodyHeight,
}) => {
  const ref = useRef(null);
  const isActive = currentIndex === itemIndex;
  return (
    <AccordionItemContainer key={`accordion-item-${itemIndex}`}>
      <AccordionTitle
        active={isActive}
        onClick={() => {
          setCurrentAccordion(isActive ? null : itemIndex);
          setBodyHeight(ref.current.clientHeight);
        }}
      >
        <span>{header}</span>
        <BsChevronUp />
      </AccordionTitle>
      <AccordionBody active={isActive} bodyHeight={bodyHeight}>
        <AccordionContent ref={ref}>
          <RichTextRender content={content} />
        </AccordionContent>
      </AccordionBody>
    </AccordionItemContainer>
  );
};

BaseAccordionItem.propTypes = {
  header: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.shape({
          target: PropTypes.string,
          href: PropTypes.string,
          innerText: PropTypes.string,
        }),
      ])
    ),
  ]).isRequired,
  itemIndex: PropTypes.number.isRequired,
  currentIndex: PropTypes.number,
  bodyHeight: PropTypes.number,
  setCurrentAccordion: PropTypes.func.isRequired,
  setBodyHeight: PropTypes.func.isRequired,
};

BaseAccordionItem.defaultProps = {
  currentIndex: null,
  bodyHeight: 0,
};

const AccordionItem = withAnalytics(BaseAccordionItem, 'setCurrentAccordion');

const Accordion = ({ items, gaCategory, gaAction }) => {
  const [currentAccordion, setCurrentAccordion] = useState(null);
  const [bodyHeight, setBodyHeight] = useState(0);
  return (
    <Container>
      <Section>
        <InnerSection>
          <AccordionContainer>
            <AccordionInner>
              {items.map(({ header, content }, i) => (
                <AccordionItem
                  key={`acc-item-${i}`}
                  bodyHeight={bodyHeight}
                  header={header}
                  content={content}
                  itemIndex={i}
                  currentIndex={currentAccordion}
                  setCurrentAccordion={setCurrentAccordion}
                  setBodyHeight={setBodyHeight}
                  gaCategory={gaCategory}
                  gaAction={gaAction}
                  gaLabel={header}
                />
              ))}
            </AccordionInner>
          </AccordionContainer>
        </InnerSection>
      </Section>
    </Container>
  );
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
            PropTypes.shape({
              target: PropTypes.string,
              href: PropTypes.string,
              innerText: PropTypes.string,
            }),
          ])
        ),
      ]),
    })
  ).isRequired,
  gaCategory: PropTypes.string,
  gaAction: PropTypes.string,
};

Accordion.defaultProps = {
  gaCategory: null,
  gaAction: null,
};

export default Accordion;
