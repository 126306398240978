import * as svars from 'style/variables';

import makeTheme from './constructor';

const orangeTheme = makeTheme(
  'Orange',
  '#C95F3B',
  '#70789C',
  '#FFFFFF',
  false,
  {
    appBackground: '#F3F5F9',
    choiceBackground: '#E5E8EE',
    choiceBoxShadow: svars.baseBoxShadow,
    border: '2px solid transparent',
    borderRadius: '6px',
  }
);

export default orangeTheme;
