import { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Link from 'components/ui/clickable/Link';
import Tag from 'components/ui/clickable/Tag';
import configurationUiService from 'services/configuration.service';
import breakpoints from 'style/breakpoints';
import * as svars from 'style/variables';

const horizontalScrollCss = (horizontalScroll) =>
  horizontalScroll
    ? `
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: 1px;
      `
    : '';

const TagListContainer = styled.div`
  padding: ${svars.spaceNormal} ${svars.spaceNormal} ${svars.spaceNormalLarge}
    ${svars.spaceNormal};
  ${({ horizontalScroll }) => horizontalScrollCss(horizontalScroll)};
  @media only screen and (${breakpoints.device.sm}) {
    ${() => horizontalScrollCss(true)};
  }
`;

const TagList = ({
  tags,
  onClick,
  style,
  horizontalScroll,
  gaCategory,
  gaAction,
  alwaysExpanded,
}) => {
  const [isExpanded, expand] = useState(alwaysExpanded);
  const { commons } = configurationUiService.wording;
  return (
    <TagListContainer horizontalScroll={horizontalScroll} style={style}>
      {(isExpanded || alwaysExpanded ? tags : tags.slice(0, 4)).map(
        ({ key, value, label }) => (
          <Tag
            gaCategory={gaCategory}
            gaAction={gaAction}
            gaLabel={key}
            onClick={() => onClick(value)}
            style={{ margin: svars.spaceSmall }}
            key={key}
            data-testid={`feedback-clickable-tag-${key}`}
          >
            {label}
          </Tag>
        )
      )}
      {tags.length > 4 && !isExpanded && !alwaysExpanded && (
        <Link
          // gaCategory={gaCategory}
          // gaAction="Search link"
          // gaLabel={title}
          style={{ whiteSpace: 'pre', paddingLeft: svars.spaceMedium }}
          onClick={() => expand(!isExpanded)}
          data-testid="feedback-see-more-button"
        >
          {commons.seeMore}
        </Link>
      )}
    </TagListContainer>
  );
};

TagList.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      icon: PropTypes.node,
    })
  ).isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]),
  onClick: PropTypes.func.isRequired,
  horizontal: PropTypes.bool,
  horizontalScroll: PropTypes.bool,
  // Whether to always show all tags, or only show the first 4
  alwaysExpanded: PropTypes.bool,
  gaCategory: PropTypes.string,
  gaAction: PropTypes.string,
  style: PropTypes.object,
};
TagList.defaultProps = {
  selected: null,
  multiple: false,
  horizontal: false,
  alwaysExpanded: false,
  gaCategory: null,
  gaAction: null,
};

export default TagList;
