import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import ClickableEmoji from 'components/ui/clickable/ClickableEmoji';
import * as svars from 'style/variables';
import { range } from 'helpers';

const getCursorPositions = (nPositions) =>
  range(1, nPositions ? nPositions + 1 : 1)
    .map(
      (i) => `&:nth-child(${i * 2 - 1}) ~ #sentiment-label-pos {
      left: ${Math.min(100, 20 * i - 10)}%;
      }
    `
    )
    .reduce((acc, item) => `${acc} ${item}`, '');

const INPUT_LABEL_DISTANCE = '2.2rem';

const SentimentRangeContainer = styled.form`
  padding-bottom: ${svars.spaceNormal};
  #sentiment-label-slider {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    position: relative;
    width: 100%;
    height: 55px;
    user-select: none;
    &::before {
      content: ' ';
      position: absolute;
      height: 2px;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    input,
    label {
      font-size: ${svars.fontSizeSmall};
      box-sizing: border-box;
      flex: 1;
      user-select: none;
      cursor: pointer;
    }
    label {
    }
    input {
      display: none;
      &:checked {
        + label::before {
          font-weight: 400;
          /* opacity: 1; */
        }
        + label::after {
          border-width: 3px;
          transform: translate(-50%, -50%);
        }
        ~ #sentiment-label-pos {
          opacity: 1;
        }
        ${({ nPositions }) => getCursorPositions(nPositions)}
      }
    }
  }
  &:valid {
    #sentiment-label-slider {
      input {
        + label::before {
          transform: translate(-50%, ${INPUT_LABEL_DISTANCE});
          transition: all 0.15s linear;
        }
        &:checked + label::before {
          transform: translate(-50%, ${INPUT_LABEL_DISTANCE}) scale(1.1);
          transition: all 0.15s linear;
        }
      }
    }
  }
`;

const EmojiSentimentRange = ({ options, onSelect, selected }) => {
  const nOptions = options.length;
  return (
    <SentimentRangeContainer nPositions={nOptions}>
      <div id="sentiment-label-slider" style={{ width: '100%' }}>
        {options.map(({ value, id }) => (
          <React.Fragment key={`slider-opt-${id}`}>
            <ClickableEmoji
              value={value}
              id={id}
              onSelect={onSelect}
              checked={id === selected}
            />
          </React.Fragment>
        ))}
      </div>
    </SentimentRangeContainer>
  );
};

EmojiSentimentRange.propTypes = {
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

EmojiSentimentRange.defaultProps = {
  selected: null,
};

export default EmojiSentimentRange;
