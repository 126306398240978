import { chnoBlobBackground } from 'components/ui/background/BlobBackground';
import svg_path_human from 'public/3135-min.jpg';
import svg_path_blob_sentiment from 'public/blob_background_sentiment_dimmed.svg';
import svg_path_blob from 'public/blob_background_yellow_background.svg';
import img_path_red from 'public/glassmorphism_red.jpeg';
import * as svars from 'style/variables';
import { transparentize } from 'polished';

import makeTheme from './constructor';

export const hoverRedBoxShadow = '0px 0px 4px 1px rgba(222, 127, 125, 0.6)';
export const hoverYellowBoxShadow = '0px 0px 4px 1px rgba(227, 148, 14, 0.6)';
export const hoverSentimentBoxShadow =
  '0px 0px 4px 1px rgba(35, 132, 198, 0.6)';
export const hoverDarkBoxShadow = '0px 0px 4px 1px rgba(0, 0, 0, 0.6)';

const redGlassTheme = makeTheme(
  'Glass (red)',
  '#DE7F7D',
  '#AAAAAA',
  svars.fontColorBase,
  '#FFFFFF',
  false,
  {
    appBackground: `url('${img_path_red}')`,
    hoverBoxShadow: hoverRedBoxShadow,

    borderRadius: '6px',
    border: '2px solid transparent',

    segmentBackground: transparentize(0.2, '#FFFFFF'),
    segmentBoxShadow: '0 0px 1px 1px rgba(222, 127, 125, 0.4)',

    choiceBackground: transparentize(0.3, '#FFFFFF'),
    choiceBackgroundOdd: '#FFFFFF',
    choiceSecondaryBackgroundHover: transparentize(0.1, '#FFFFFF'),
    choiceHoverTextColor: '#FFFFFF',
    choiceBackgroundDisabled: '#F5BAB9',
    choiceBoxShadow: '0 0px 1px 1px rgba(222, 127, 125, 0.2)',

    tagBackground: transparentize(0.3, '#FFFFFF'),
    tagBackgroundColorHover: transparentize(0.1, '#FFFFFF'),
    tagBorderColor: transparentize(0.3, '#FFFFFF'),
    tagBorderColorHover: transparentize(0.3, '#FFFFFF'),
    tagBoxShadow: '0 0px 1px 1px rgba(222, 127, 125, 0.2)',

    searchBarBackground: transparentize(0.3, '#FFFFFF'),
    searchBarBorderColor: transparentize(0.3, '#DE7F7D'),
    searchBarBoxShadow: `0px 0px 3px rgba(222, 127, 125)`,
    searchBarBorderWidth: '1px',

    textAreaBackground: transparentize(0.3, '#FFFFFF'),
    textAreaBackgroundFocus: transparentize(0.1, '#FFFFFF'),

    inputBorderColorFocus: '#DE7F7D',
    inputBoxShadowFocus: hoverRedBoxShadow,

    iconBackground: transparentize(0.3, '#FFFFFF'),

    modalBackground: transparentize(0.1, '#FFFFFF'),
    modalHeaderBackground: transparentize(0.2, '#DE7F7D'),
  }
);

const yellowGlassTheme = makeTheme(
  'Glass (yellow)',
  '#e3940e',
  '#2384C6',
  svars.fontColorBase,
  '#FFFFFF',
  false,
  {
    appBackground: `url('${svg_path_blob}')`,

    hoverBoxShadow: hoverYellowBoxShadow,

    borderRadius: '6px',
    border: '2px solid transparent',

    segmentBackground: transparentize(0.2, '#FFFFFF'),
    segmentBoxShadow: '0 0px 1px 1px rgba(227, 148, 14, 0.4)',

    choiceBackground: transparentize(0.3, '#FFFFFF'),
    choiceBackgroundOdd: '#FFFFFF',
    choiceSecondaryBackgroundHover: transparentize(0.1, '#FFFFFF'),
    choiceHoverTextColor: '#FFFFFF',
    choiceBackgroundDisabled: '#F7D090',
    choiceBoxShadow: '0 0px 1px 1px rgba(227, 148, 14, 0.2)',

    tagBackground: transparentize(0.3, '#FFFFFF'),
    tagBackgroundColorHover: transparentize(0.1, '#FFFFFF'),
    tagBorderColor: transparentize(0.3, '#FFFFFF'),
    tagBorderColorHover: transparentize(0.3, '#FFFFFF'),
    tagBoxShadow: '0 0px 1px 1px rgba(227, 148, 14, 0.2)',

    searchBarBackground: transparentize(0.3, '#FFFFFF'),
    searchBarBorderColor: transparentize(0.3, '#e3940e'),
    searchBarBoxShadow: '0 0px 3px rgba(227, 148, 14)',
    searchBarBorderWidth: '1px',

    textAreaBackground: transparentize(0.3, '#FFFFFF'),
    textAreaBackgroundFocus: transparentize(0.1, '#FFFFFF'),

    inputBorderColorFocus: '#e3940e',
    inputBoxShadowFocus: hoverYellowBoxShadow,

    iconBackground: transparentize(0.3, '#FFFFFF'),

    modalBackground: transparentize(0.2, '#FFFFFF'),
    modalHeaderBackground: transparentize(0.2, '#e3940e'),
  }
);

const sentimentGlassTheme = makeTheme(
  'Glass (sentiment)',
  '#2384C6',
  '#B59B9B',
  svars.fontColorBase,
  '#FFFFFF',
  false,
  {
    appBackground: `url('${svg_path_blob_sentiment}')`,

    hoverBoxShadow: hoverSentimentBoxShadow,

    borderRadius: '6px',
    border: '2px solid transparent',

    segmentBackground: transparentize(0.2, '#FFFFFF'),
    segmentBoxShadow: '0 0px 1px 1px rgba(35, 132, 198, 0.4)',

    choiceBackground: transparentize(0.3, '#FFFFFF'),
    choiceBackgroundOdd: '#FFFFFF',
    choiceSecondaryBackgroundHover: transparentize(0.1, '#FFFFFF'),
    choiceHoverTextColor: '#FFFFFF',
    choiceBackgroundDisabled: '#93C8EC',
    choiceBoxShadow: '0 0px 1px 1px rgba(35, 132, 198, 0.2)',

    tagBackground: transparentize(0.3, '#FFFFFF'),
    tagBackgroundColorHover: transparentize(0.1, '#FFFFFF'),
    tagBorderColor: transparentize(0.3, '#FFFFFF'),
    tagBorderColorHover: transparentize(0.3, '#FFFFFF'),
    tagBoxShadow: '0 0px 1px 1px rgba(35, 132, 198, 0.2)',

    searchBarBackground: transparentize(0.3, '#FFFFFF'),
    searchBarBorderColor: transparentize(0.3, '#2384C6'),
    searchBarBoxShadow: '0 0px 3px rgba(35, 132, 198, 14)',
    searchBarBorderWidth: '1px',

    textAreaBackground: transparentize(0.3, '#FFFFFF'),
    textAreaBackgroundFocus: transparentize(0.1, '#FFFFFF'),

    inputBorderColorFocus: '#2384C6',
    inputBoxShadowFocus: hoverSentimentBoxShadow,

    iconBackground: transparentize(0.3, '#FFFFFF'),

    modalBackground: transparentize(0.2, '#FFFFFF'),
    modalHeaderBackground: transparentize(0.2, '#2384C6'),
  }
);

const humanGlassTheme = makeTheme(
  'Glass (image)',
  '#2384C6',
  '#B59B9B',
  svars.fontColorBase,
  '#FFFFFF',
  false,
  {
    appBackground: `url('${svg_path_human}')`,

    hoverBoxShadow: hoverSentimentBoxShadow,

    borderRadius: '6px',
    border: '2px solid transparent',

    segmentBackground: transparentize(0.1, '#FFFFFF'),
    segmentBoxShadow: '0 0px 1px 1px rgba(35, 132, 198, 0.4)',

    choiceBackground: transparentize(0.3, '#FFFFFF'),
    choiceBackgroundOdd: '#FFFFFF',
    choiceSecondaryBackgroundHover: transparentize(0.1, '#FFFFFF'),
    choiceHoverTextColor: '#FFFFFF',
    choiceBackgroundDisabled: '#93C8EC',
    choiceBoxShadow: '0 0px 1px 1px rgba(35, 132, 198, 0.2)',

    tagBackground: transparentize(0.3, '#FFFFFF'),
    tagBackgroundColorHover: transparentize(0.1, '#FFFFFF'),
    tagBorderColor: transparentize(0.5, '#2384C6'),
    tagBorderColorHover: transparentize(0.5, '#2384C6'),
    tagBoxShadow: '0 0px 1px 1px rgba(35, 132, 198, 0.2)',

    searchBarBackground: transparentize(0.3, '#FFFFFF'),
    searchBarBorderColor: transparentize(0.3, '#2384C6'),
    searchBarBoxShadow: '0 0px 3px rgba(35, 132, 198, 14)',
    searchBarBorderWidth: '1px',

    textAreaBackground: transparentize(0.3, '#FFFFFF'),
    textAreaBackgroundFocus: transparentize(0.1, '#FFFFFF'),

    inputBorderColorFocus: '#2384C6',
    inputBoxShadowFocus: hoverSentimentBoxShadow,

    iconBackground: transparentize(0.3, '#FFFFFF'),

    modalBackground: transparentize(0.2, '#FFFFFF'),
    modalHeaderBackground: transparentize(0.2, '#2384C6'),
  }
);

const chnoPrimary = '#016fb8';
const boxShadowColor = 'rgba(0, 0, 0, 0.11)';
const hoverChnoBoxShadow = `0px 0px 4px 1px ${transparentize(
  0.2,
  chnoPrimary
)}`;
const yellowGlassLargeBlobTheme = makeTheme(
  'Glass (large yellow)',
  chnoPrimary,
  '#FFE970',
  svars.fontColorBase,
  '#FFFFFF',
  false,
  {
    appBackground: `url('data:image/svg+xml;utf8, ${chnoBlobBackground}')`,

    hoverBoxShadow: hoverChnoBoxShadow,

    borderRadius: '6px',
    border: '2px solid transparent',

    segmentBackground: transparentize(0.2, '#FFFFFF'),
    segmentBoxShadow: `0 0px 1px 1px ${boxShadowColor}`,

    choiceBackground: transparentize(0.3, '#FFFFFF'),
    choiceBackgroundOdd: '#FFFFFF',
    choiceSecondaryBackgroundHover: transparentize(0.1, '#FFFFFF'),
    choiceHoverTextColor: '#FFFFFF',
    choiceBackgroundDisabled: '#F7D090',
    choiceBoxShadow: `0 0px 1px 1px ${boxShadowColor}`,

    tagBackground: transparentize(0.3, '#FFFFFF'),
    tagBackgroundColorHover: transparentize(0.1, '#FFFFFF'),
    tagBorderColor: transparentize(0.3, '#FFFFFF'),
    tagBorderColorHover: transparentize(0.3, '#FFFFFF'),
    tagBoxShadow: `0 0px 1px 1px ${boxShadowColor}`,

    searchBarBackground: transparentize(0.3, '#FFFFFF'),
    searchBarBorderColor: transparentize(0.3, chnoPrimary),
    searchBarBoxShadow: `0 0px 3px ${boxShadowColor}`,
    searchBarBorderWidth: '1px',

    textAreaBackground: transparentize(0.3, '#FFFFFF'),
    textAreaBackgroundFocus: transparentize(0.1, '#FFFFFF'),

    inputBorderColorFocus: chnoPrimary,
    inputBoxShadowFocus: hoverChnoBoxShadow,

    iconBackground: transparentize(0.3, '#FFFFFF'),

    modalBackground: transparentize(0.2, '#FFFFFF'),
    modalHeaderBackground: transparentize(0.2, chnoPrimary),
  }
);
const darkGlassTheme = makeTheme(
  'Glass (dark)',
  '#000000',
  '#B59B9B',
  svars.fontColorBase,
  '#FFFFFF',
  false,
  {
    appBackground: 'black',

    hoverBoxShadow: hoverDarkBoxShadow,

    borderRadius: '6px',
    border: '2px solid transparent',

    segmentBackground: transparentize(0.4, '#FFFFFF'),
    segmentBoxShadow: '0 0px 1px 1px rgba(0, 0, 0, 0.4)',

    choiceBackground: transparentize(0.3, '#FFFFFF'),
    choiceBackgroundOdd: '#FFFFFF',
    choiceSecondaryBackgroundHover: transparentize(0.1, '#FFFFFF'),
    choiceHoverTextColor: '#FFFFFF',
    choiceBackgroundDisabled: '#E9E9E9E9',
    choiceBoxShadow: '0 0px 1px 1px rgba(0, 0, 0, 0.2)',

    tagBackground: transparentize(0.3, '#FFFFFF'),
    tagBackgroundColorHover: transparentize(0.1, '#FFFFFF'),
    tagBorderColor: transparentize(0.3, '#FFFFFF'),
    tagBorderColorHover: transparentize(0.3, '#FFFFFF'),
    tagBoxShadow: '0 0px 1px 1px rgba(0, 0, 0, 0.2)',

    searchBarBackground: transparentize(0.3, '#FFFFFF'),
    searchBarBorderColor: transparentize(0.3, '#000000'),
    searchBarBoxShadow: '0 0px 3px rgba(0, 0, 0, 14)',
    searchBarBorderWidth: '1px',

    textAreaBackground: transparentize(0.3, '#FFFFFF'),
    textAreaBackgroundFocus: transparentize(0.1, '#FFFFFF'),

    inputBorderColorFocus: '#000000',
    inputBoxShadowFocus: hoverDarkBoxShadow,

    iconBackground: transparentize(0.3, '#FFFFFF'),

    modalBackground: transparentize(0.2, '#FFFFFF'),
    modalHeaderBackground: transparentize(0.2, '#000000'),
  }
);

// const blueGlassTheme = makeTheme(
//   "Glass (blue)",
//   "#2384C6",
//   "#FFE970",
//   svars.fontColorBase,
//   "#FFFFFF",
//   {
//     appBackground: `url('${img_path_blue}')`,

//     borderRadius: "6px",
//     border: "2px solid transparent",

//     choiceBackground: transparentize(0.3, "#FFFFFF"),
//     choiceSecondaryBackground: transparentize(0.3,"#2384C6"),
//     choiceBorderRadius: "10px",
//     choiceBoxShadow: glassBoxShadow,
//     choiceBackdrop: "blur(13.5px)",
//     choiceBorderColor: "1px solid rgba(255, 255, 255, 0.18)",
//     choiceSecondaryTextColorHover: "#FFFFFF",
//     choiceHoverTextColor: "#FFFFFF",

//     tagBackground: transparentize(0.3, "#FFFFFF"),
//     tagBorderColor: transparentize(0.3, "#FFFFFF"),
//     tagBackgroundColorSecondary: transparentize(0.3,"#2384C6"),
//     tagSecondaryTextColor: "#FFFFFF",

//     searchBarBackground: transparentize(0.3, "#FFFFFF"),
//     searchBarBoxShadow: glassBoxShadow,
//     searchBarBorderWidth : '1px',

//     textAreaBackground: transparentize(0.3, "#FFFFFF"),

//     segmentBackground: transparentize(0.4, "#FFFFFF"),

//     fontColorPrimaryHover: "#FFFFFF",
//     primaryHovered: transparentize(0.3,"#2384C6"),

//     iconBackground: transparentize(0.3, "#FFFFFF")
//   }
// );

// const darkGlassTheme = makeTheme(
//   "Glass (dark)",
//   "#007DFF",
//   "#FFE970",
//   "#FFFFFF",
//   svars.fontColorBase,
//   {
//     appBackground: "#212121",
//     choiceBackground: "rgba(255, 255, 255, 0.09)",
//     choiceBorderRadius: "10px",
//     choiceBoxShadow: "0 8px 32px 0 rgba(255, 255, 255, 0.07)",
//     choiceBackdrop: "blur(13.5px)",
//     choiceBorderColor: "1px solid rgba(255, 255, 255, 0.07)",
//     searchBarBorderRadius: "30px",
//   }
// );

export {
  redGlassTheme,
  yellowGlassTheme,
  sentimentGlassTheme,
  darkGlassTheme,
  humanGlassTheme,
  yellowGlassLargeBlobTheme,
};
