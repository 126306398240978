import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import * as svars from 'style/variables';

const makeHeader = (
  fontSize,
  fontWeight,
  paddingBottom,
  fontStyle = 'normal',
  lineHeight = null
) => css`
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  font-style: ${({ italic }) => (italic ? 'italic' : fontStyle)};
  padding-bottom: ${paddingBottom};
  color: ${({ primary, success, danger, info, theme }) =>
    primary
      ? theme.primary
      : success
        ? svars.colorSuccess
        : danger
          ? svars.colorDanger
          : info
            ? svars.fontColorLighter
            : 'inherit'};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  display: ${({ inline }) => (inline ? 'inline' : 'block')};
  ${lineHeight ? `line-height: ${lineHeight};` : ''}
`;

export const H1 = styled.header`
  ${makeHeader(svars.fontSizeBig, svars.fontWeightSemiBold, svars.spaceLarge)}
`;
export const H2 = styled.header`
  ${makeHeader(
    svars.fontSizeXLarge,
    svars.fontWeightMedium,
    svars.spaceLarge,
    'normal',
    svars.lineHeightSizeXLarge
  )}
`;
export const H3 = styled.header`
  ${makeHeader(svars.fontSizeLarge, svars.fontWeightMedium, svars.spaceMedium)}
`;

export const H4 = styled.header`
  ${makeHeader(svars.fontSizeMedium, svars.fontWeightBase, svars.spaceMedium)}
`;

export const H5 = styled.header`
  ${makeHeader(svars.fontSizeBase, svars.fontWeightLight, svars.spaceMedium)}
`;

const RequiredHeaderContainer = styled(H3)`
  flex-shrink: 1;
  position: relative;
  padding-bottom: 0;
  font-weight: ${svars.fontWeightMedium};
  & span > p,
  span > h2 {
    display: inline;
  }
  ${({ required }) =>
    required
      ? `
    & span {
      /* Make sure required suffix stays visible */
      margin-right: 0.3rem;
      span > p:last-child::after {
        content: '*';
        color: ${svars.colorDanger};
        position: absolute;
        margin-left: ${svars.spaceSmall};
      }
    }
    `
      : ''}
`;

export function RequiredHeader({ required, children, subheader, style }) {
  return (
    <RequiredHeaderContainer
      style={style}
      required={required}
      data-testid={
        required
          ? 'fb-campaign-preview-pane-required-title'
          : 'fb-campaign-preview-pane-not-required-title'
      }
      id={required ? 'requiredTitle' : 'notRequiredTitle'}
      subheader={subheader}
    >
      <span style={{ display: 'flex', flexDirection: 'column' }}>
        {children}
      </span>
      {subheader && (
        <H5
          data-testid="fb-campaign-preview-pane-description"
          style={{
            color: svars.fontColorLighter,
            paddingBottom: 0,
            paddingTop: svars.spaceSmall,
          }}
        >
          {subheader}
        </H5>
      )}
    </RequiredHeaderContainer>
  );
}

RequiredHeader.propTypes = {
  style: PropTypes.object,
  required: PropTypes.bool,
  children: PropTypes.node,
  subheader: PropTypes.string,
};

RequiredHeader.defaultProps = {
  required: false,
  children: null,
  subheader: null,
};
