import React, { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { IoIosArrowDropright } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import { selectIdentification, setIdentification } from 'store/review';

import Divider from 'components/ui/Divider';
import { H5 } from 'components/ui/Header';
import Link from 'components/ui/clickable/Link';
import SearchableDropdown from 'components/ui/clickable/SearchableDropdown';
import MultiChoice from 'components/ui/input/MultiChoice';
import { TextInputWithIcon } from 'components/ui/input/TextInput';
import MandatoryFieldIndicator from 'components/ui/input/label';

import commonConfigurationService from 'services/configuration.service';
import feedbackCampaignService, {
  identificationFormTypes,
} from 'services/feedbackCampaign.service';

import breakpoints from 'style/breakpoints';
import * as svars from 'style/variables';

const openKeyframe = keyframes`
from {
    max-height: 0;
  }
  to {
    max-height: 25rem;
  }
`;
const closeKeyframe = keyframes`
from {
    max-height: 25rem;
  }
  to {
    max-height: 0;
  }
`;
const hideScroll = keyframes`
  from, to { overflow: hidden; } `;

const FormContainer = styled.div`
  padding: 0 ${svars.spaceNormal};
  animation:
    ${({ noAnimation, toggled }) =>
        (noAnimation && 'null') || (toggled && openKeyframe) || closeKeyframe}
      0.4s backwards,
    ${hideScroll} 0.2s backwards;
`;

const FaChevronCircleRight = styled(IoIosArrowDropright)`
  transform: ${({ expanded }) => (expanded ? 'rotate(90deg)' : '')};
  transition: ${svars.transitionBase};
`;

const IdentificationInputContainer = styled.div`
  width: 100%;
  padding-bottom: ${svars.spaceMedium};
  padding-top: ${svars.spaceSmall};
  max-width: ${({ limited }) => (limited ? '450px' : 'none')};
`;

const IdentificationInputLabel = styled.div`
  padding-left: ${svars.spaceSmall};
  font-weight: ${svars.fontWeightMedium};
  font-size: ${svars.fontSizeSmall};
  min-width: 50px;
  padding-bottom: ${svars.spaceNormalLarge};
  @media only screen and (${breakpoints.device.sm}) {
    text-align: left;
  }
`;

const IdentificationLink = styled(Link)`
  flex-grow: 1;
  text-decoration: none;
  display: inline-flex;
  justify-content: space-between;
  padding: ${svars.spaceNormalLarge} ${svars.spaceNormal};
  color: ${({ theme, expanded }) =>
    expanded ? theme.primary : svars.fontColorBase};
`;

const IdentificationFormSummaryItem = styled.div`
  margin: 0 ${svars.spaceNormal};
  max-height: 2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${svars.fontSizeXSmall};
`;

const IdentificationFormSummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  color: ${svars.fontColorLighter};

  @media only screen and (${breakpoints.device.xs}) {
    flex-direction: column;
    & ${IdentificationFormSummaryItem} {
      flex-direction: row;
      & svg {
        padding-right: ${svars.spaceSmall};
      }
    }
  }
`;

function IdentificationInputLayout({ children, limited, formElement }) {
  return (
    <IdentificationInputContainer limited={limited}>
      <IdentificationInputLabel>
        {formElement.label}
        <MandatoryFieldIndicator withLabel mandatory={formElement.mandatory} />
      </IdentificationInputLabel>
      {children}
    </IdentificationInputContainer>
  );
}

IdentificationInputLayout.propTypes = {
  children: PropTypes.node.isRequired,
  limited: PropTypes.bool,
  formElement: PropTypes.shape({
    label: PropTypes.string.isRequired,
    mandatory: PropTypes.bool,
  }).isRequired,
};

IdentificationInputLayout.defaultProps = {
  limited: false,
};

const iconMap = {
  HiOutlineMail: <HiOutlineMail />,
};

function IdentificationFormSummary({ fields }) {
  return (
    <IdentificationFormSummaryContainer>
      {fields.map(({ label, checked, mandatory }) => (
        <IdentificationFormSummaryItem key={`idfs-${label}`}>
          <FaCheckCircle
            style={{
              display: 'block',
              color: checked ? svars.colorSuccess : svars.colorGreyLight,
            }}
          />
          <span>
            {label}
            <MandatoryFieldIndicator mandatory={mandatory} />
          </span>
        </IdentificationFormSummaryItem>
      ))}
    </IdentificationFormSummaryContainer>
  );
}

IdentificationFormSummary.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
      checked: PropTypes.bool,
      mandatory: PropTypes.bool,
    })
  ).isRequired,
};

function IdentificationForm() {
  const dispatch = useDispatch();

  const identification = useSelector(selectIdentification);

  const { expandedForm } = feedbackCampaignService;
  const {
    identificationForm,
    wording: { identificationWording },
  } = feedbackCampaignService;
  const { commons } = commonConfigurationService.wording;
  const [showIdentificationForm, setShowIdentificationForm] =
    useState(expandedForm);

  const formFields = [];
  identificationForm.forEach(({ id, label, mandatory }) => {
    formFields.push({
      label,
      mandatory,
      value: identification[id] || '',
      checked: !!identification[id],
    });
  });
  if (!formFields.length) return null;
  return (
    <React.Fragment>
      <Divider style={{ margin: '0 -1.2rem', width: 'calc(100% + 2.4rem)' }} />
      <IdentificationLink
        expanded={
          expandedForm || (showIdentificationForm && 'true') || undefined
        }
        onClick={() =>
          !expandedForm && setShowIdentificationForm(!showIdentificationForm)
        }
      >
        {!expandedForm ? (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <H5 style={{ padding: 0, lineHeight: '1.25rem' }}>
              {identificationWording.header}
            </H5>
            <IdentificationFormSummary fields={formFields} />
            <FaChevronCircleRight
              style={{
                marginLeft: svars.spaceMedium,
                fontSize: svars.fontSizeLarge,
              }}
              expanded={(showIdentificationForm && 'true') || undefined}
            />
          </div>
        ) : null}
      </IdentificationLink>
      {showIdentificationForm || expandedForm ? (
        <FormContainer
          noAnimation={expandedForm}
          toggled={showIdentificationForm || undefined}
        >
          {identificationWording.subheader ? (
            <div
              style={{
                paddingBottom: svars.spaceNormalLarge,
                marginRight: svars.spaceLarge,
              }}
            >
              {identificationWording.subheader}
            </div>
          ) : null}
          <div>
            {identificationForm.map((identificationFormElement) => {
              switch (identificationFormElement.type) {
                case identificationFormTypes.INPUT: {
                  return (
                    <IdentificationInputLayout
                      key={`tag_id_${identificationFormElement.id}`}
                      limited
                      formElement={identificationFormElement}
                    >
                      <TextInputWithIcon
                        left
                        icon={iconMap[identificationFormElement.icon]}
                        type={identificationFormElement.name}
                        placeholder={identificationFormElement.placeholder}
                        containerStyle={{
                          flexGrow: 1,
                        }}
                        value={
                          identification[identificationFormElement.id] || ''
                        }
                        onChange={({ target: { value } }) => {
                          dispatch(
                            setIdentification({
                              id: identificationFormElement.id,
                              value,
                            })
                          );
                        }}
                      />
                    </IdentificationInputLayout>
                  );
                }
                case identificationFormTypes.MULTIPLE_CHOICE: {
                  return (
                    <IdentificationInputLayout
                      key={`tag_id_${identificationFormElement.id}`}
                      limited={!!identificationFormElement.dropdown}
                      formElement={identificationFormElement}
                    >
                      {identificationFormElement.dropdown ? (
                        <SearchableDropdown
                          alwaysUp
                          options={identificationFormElement.items}
                          onChange={(item) => {
                            dispatch(
                              setIdentification({
                                id: identificationFormElement.id,
                                value: item ? item.value : item,
                              })
                            );
                          }}
                          value={
                            identification[identificationFormElement.id] || ''
                          }
                          placeholder={identificationFormElement.placeholder}
                          noResultText={commons.noTagResults}
                        />
                      ) : (
                        <MultiChoice
                          horizontal
                          compact
                          collapsable
                          relaxed={expandedForm}
                          centered
                          selected={
                            identification[identificationFormElement.id] || ''
                          }
                          options={identificationFormElement.items}
                          onChange={(value) => {
                            dispatch(
                              setIdentification({
                                id: identificationFormElement.id,
                                value,
                              })
                            );
                          }}
                        />
                      )}
                    </IdentificationInputLayout>
                  );
                }
                default:
                  return null;
              }
            })}
          </div>
        </FormContainer>
      ) : null}
    </React.Fragment>
  );
}

export default IdentificationForm;
