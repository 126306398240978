import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import amplitude from 'amplitude-js';
import campaignCommonConfigurationService from 'services/configuration.service';
import config from 'config';
import PropTypes from 'prop-types';

const initializeAmplitude = () => {
  // Initialize Amplitude
  if (config.amplitude.key) {
    amplitude.getInstance().init(config.amplitude.key, null, {
      apiEndpoint: 'api.eu.amplitude.com',
      serverZone: 'EU',
      serverZoneBasedApi: true,
      batchEvents: true,
      eventUploadThreshold: 2,
      eventUploadPeriodMillis: 4000,
      appVersion: process.env.REACT_APP_VERSION,
    });
    return true;
  }
  return false;
};

export const logEvent = ({ category, action, label, nonInteraction }) => {
  const logProps = { category };
  if (label) logProps.label = label;
  if (nonInteraction) logProps.nonInteraction = nonInteraction;
  if (config.amplitude.key) amplitude.getInstance().logEvent(action, logProps);
};

export /**
 * Set user id to analytics apps (used at login time).
 *
 * @param {*} userId the user id.
 */

const setAnalyticsUserGlobally = () => {
  if (config.amplitude.key) {
    const identify = new amplitude.Identify();
    identify.set('subdomain', window.location.hostname?.split?.('.')?.[0]);
    identify.set(
      'campaign_language',
      campaignCommonConfigurationService.language
    );
    identify.set(
      'campaign_type',
      campaignCommonConfigurationService.isSurvey ? 'survey' : 'feedback_app'
    );
    identify.set('campaign_id', campaignCommonConfigurationService.id);
    amplitude.identify(identify);
  }
};

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState({
    amplitude: false,
  });
  useEffect(() => {
    const analyticsStatus = {
      amplitude: initializeAmplitude(),
    };
    if (Object.values(analyticsStatus).some((x) => x)) {
      setInitialized(analyticsStatus);
    }
  }, []);
  useEffect(() => {
    if (initialized.amplitude) {
      amplitude.getInstance().logEvent('page view', {
        path: location.pathname,
        search: location.search,
      });
    }
  }, [initialized, location]);
};

export function withAnalytics(WrappedComponent, logMethod = 'onClick') {
  function WithAnalyticsWrapper({ gaCategory, gaAction, gaLabel, ...props }) {
    if (!gaCategory) return <WrappedComponent {...props} />;

    let logMethodValue = props[logMethod];

    logMethodValue = useCallback(
      (...params) => {
        logEvent({
          category: gaCategory,
          action: gaAction,
          label: gaLabel,
        });
        if (props[logMethod]) {
          props[logMethod](...params);
        }
      },
      [gaCategory, gaAction, gaLabel, props]
    );
    const finalProps = { [logMethod]: logMethodValue };
    return <WrappedComponent {...props} {...finalProps} />;
  }

  WithAnalyticsWrapper.propTypes = {
    gaCategory: PropTypes.string,
    gaAction: PropTypes.string,
    gaLabel: PropTypes.string,
  };
  return WithAnalyticsWrapper;
}

export default usePageTracking;
