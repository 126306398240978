import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import configurationUiService from 'services/configuration.service';

import * as svars from 'style/variables';

const Container = styled.span`
  color: red;
  font-weight: ${svars.fontWeightSemiBold};
  margin-left: ${svars.spaceSmall};
`;

const LabelContainer = styled.span`
  color: ${svars.fontColorLighter};
  font-size: ${svars.fontSizeXSmall};
  font-weight: ${svars.fontWeightLight};
  margin-left: ${svars.spaceSmall};
`;

function MandatoryFieldIndicator({ mandatory, withLabel }) {
  const { commons } = configurationUiService.wording;
  return mandatory ? (
    <Container>
      *
      {withLabel ? (
        <LabelContainer>({commons.mandatory})</LabelContainer>
      ) : null}
    </Container>
  ) : null;
}

MandatoryFieldIndicator.propTypes = {
  mandatory: PropTypes.bool,
};
MandatoryFieldIndicator.defaultProps = {
  mandatory: false,
};

export default MandatoryFieldIndicator;
