import styled from 'styled-components';

import { withAnalytics } from 'app/analytics';
import * as svars from 'style/variables';

const Link = styled.a`
  text-decoration: underline;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: ${({ base }) => (base ? 'inherit' : svars.fontSizeBase)};
  transition: ${svars.transitionBase};
  color: ${({ disabled }) => (disabled ? svars.fontColorDisabled : 'inherit')};

  &:hover {
    color: ${({ theme, disabled }) =>
      disabled ? svars.fontColorDisabled : theme.primary};
  }
`;

Link.defaultProps = { disabled: false };

const AnalyticsLink = withAnalytics(Link);
export default AnalyticsLink;
