import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectQuestion,
  selectQuestionValue,
  answerQuestion,
  selectLoading,
  focusOnNextButton,
} from 'store/survey';
import MultiChoice from 'components/ui/input/MultiChoice';
import TextArea from 'components/ui/input/TextArea';
import { RequiredHeader } from 'components/ui/Header';
import * as svars from 'style/variables';
import styled from 'styled-components';
import FadeInOut from 'components/ui/animation/FadeInOut';
import breakpoints from 'style/breakpoints';
import { TextInputWithIcon } from 'components/ui/input/TextInput';

import RichTextRender from 'components/ui/RichTextRender';
import configurationUiService from 'services/configuration.service';

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${svars.spaceMedium} ${svars.spaceMedium} ${svars.spaceNormal}
    ${svars.spaceMedium};
  height: calc(100% - ${svars.spaceNormal} - ${svars.spaceMedium});
  overflow: hidden;
  animation: fadeIn 0.5s;
  transition: max-height 0.3s ease;
  min-height: 50vh;
  justify-content: center;
`;

const ScrollableContainer = styled.div`
  margin: 0;
  overflow: clip auto;
  margin-top: ${svars.spaceMedium};

  @media only screen and (${breakpoints.device.sm}) {
    width: 100%;
  }
`;

function SurveyQuestion() {
  const {
    id: questionId,
    title,
    description,
    options,
    type,
    short_text: shortText,
    multiple,
    required,
    randomize,
    vertical,
  } = useSelector(selectQuestion);
  const questionValue = useSelector(selectQuestionValue);
  const isLoading = useSelector(selectLoading);
  const dispatch = useDispatch();
  const answer = useCallback(
    (value) => {
      dispatch(answerQuestion({ id: questionId, value }));
    },
    [dispatch, answerQuestion, questionId]
  );
  const focusNext = useCallback(() => {
    dispatch(focusOnNextButton());
  }, []);
  const {
    wording: { commons },
  } = configurationUiService;
  const isTextSurveyQuestion = type === 'TextSurveyQuestion';
  return (
    <FadeInOut show style={{ flexGrow: 1 }}>
      <QuestionContainer>
        <span
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            maxWidth: svars.textMaxWidth,
            margin: '0 auto',
            paddingBottom: svars.spaceMedium,
            overflow: 'hidden',
          }}
        >
          <RequiredHeader
            style={{
              overflow: 'clip auto',
              marginBottom: svars.spaceMedium,
              flexShrink: 0,
              maxHeight:
                (isTextSurveyQuestion && ((shortText && '90%') || '65%')) ||
                '60%',
            }}
            required={required}
            data-testid={`question-${questionId}`}
            subheader={
              description ? <RichTextRender content={description} /> : null
            }
          >
            <RichTextRender content={title} />
          </RequiredHeader>
          {(isTextSurveyQuestion &&
            ((shortText && (
              <TextInputWithIcon
                fluid
                autoFocus
                icon={
                  questionValue?.length ? (
                    <span
                      style={{
                        fontSize: svars.fontSizeXSmall,
                        color: svars.fontColorLighter,
                      }}
                    >
                      {questionValue?.length}/150
                    </span>
                  ) : null
                }
                disabled={isLoading}
                value={questionValue}
                onChange={({ target: { value } }) => answer(value)}
                required={required}
                style={{
                  margin: 0,
                  width: '100%',
                  maxWidth: svars.textMaxWidth,
                  fontSize: svars.fontSizeMedium,
                  paddingRight: '3rem',
                  alignSelf: 'stretch',
                }}
                maxLength={150}
                placeholder={commons.textInputPlaceholder}
                data-testid="fb-question-text-input"
              />
            )) || (
              <TextArea
                placeholder={commons.textInputPlaceholder}
                autoFocus
                disabled={isLoading}
                value={questionValue}
                onChange={({ target: { value } }) => answer(value)}
                required={required}
                style={{
                  width: '100%',
                  minHeight: '10rem',
                  maxWidth: svars.textMaxWidth,
                  fontSize: svars.fontSizeMedium,
                }}
                maxLength={2000}
                data-testid="fb-question-text-area"
              />
            ))) ||
            null}
          {type === 'MultiChoiceSurveyQuestion' ? (
            <ScrollableContainer>
              <MultiChoice
                richText
                horizontal={!vertical}
                disabled={isLoading}
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'stretch',
                }}
                onBlur={!multiple ? focusNext : MultiChoice.onBlur}
                options={options}
                multiple={multiple}
                randomize={randomize}
                selected={questionValue}
                onChange={answer}
                required={required}
              />
            </ScrollableContainer>
          ) : null}
        </span>
      </QuestionContainer>
    </FadeInOut>
  );
}

SurveyQuestion.propTypes = {};

export default SurveyQuestion;
