import React from 'react';
import { FaSearch } from 'react-icons/fa';

import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';

import Divider from 'components/ui/Divider';
import ClickableIcon from 'components/ui/clickable/ClickableIcon';
import TagList from 'components/ui/clickable/TagList';
import MultiChoice from 'components/ui/input/MultiChoice';
import * as svars from 'style/variables';

const TagWallContainer = styled.div`
  overflow: clip auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
`;

function RelaxedTagWall({ tagSectionOptions, onClick, style }) {
  return (
    <TagWallContainer style={style}>
      {tagSectionOptions?.map(([title, tagOptions]) => (
        <MultiChoice
          key={`head-${title}`}
          options={tagOptions}
          onChange={onClick}
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: svars.textMaxWidth,
            margin: '0 auto',
          }}
          centered
          compact
        />
      ))}
    </TagWallContainer>
  );
}

RelaxedTagWall.propTypes = {
  tagSectionOptions: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
          })
        ),
        PropTypes.bool,
      ])
    )
  ).isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]),
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
};

function BasicTagWall({ tagSectionOptions, onClick, style, goToSearch }) {
  return (
    <TagWallContainer style={style}>
      {tagSectionOptions?.map(([title, tagOptions, addSearchLink]) => (
        <React.Fragment key={`head-${title}`}>
          <Divider
            label={`${title}`}
            primary
            action={
              addSearchLink && goToSearch ? (
                <ClickableIcon
                  primary
                  inline
                  icon={<FaSearch />}
                  gaCategory="Search"
                  gaAction="Search link"
                  gaLabel={title}
                  style={{
                    fontSize: 'inherit',
                    margin: 0,
                    marginRight: svars.spaceNormal,
                  }}
                  onClick={() => goToSearch(title)}
                  testid={`feedback-home-tag-search-${title}`}
                />
              ) : null
            }
          />
          <TagList
            tags={tagOptions}
            onClick={onClick}
            key={`tagl-${title}`}
            gaCategory="Search"
            gaAction="Category tag"
          />
        </React.Fragment>
      ))}
    </TagWallContainer>
  );
}

BasicTagWall.propTypes = {
  tagSectionOptions: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
          })
        ),
        PropTypes.bool,
      ])
    )
  ).isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]),
  onClick: PropTypes.func.isRequired,
  goToSearch: PropTypes.func,
  style: PropTypes.object,
};

function TagWall(props) {
  const { relaxed } = useTheme();
  if (relaxed) {
    return <RelaxedTagWall {...props} />;
  } else {
    return <BasicTagWall {...props} />;
  }
}

TagWall.propTypes = {
  tagSectionOptions: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
          })
        ),
        PropTypes.bool,
      ])
    )
  ).isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]),
  onClick: PropTypes.func.isRequired,
};
TagWall.defaultProps = {
  selected: null,
};
export default TagWall;
