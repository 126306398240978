import { css } from 'styled-components';

import { darken, transparentize } from 'polished';

export const rootMaxWidth = '35rem';

export const colorWhite = '#FFFFFF';

export const whiteTransparentLight = transparentize(0.08, '#FFFFFF');
export const whiteTransparentMedium = transparentize(0.22, '#FFFFFF');
export const whiteTransparentStrong = transparentize(0.45, '#FFFFFF');

export const colorGrey = '#949499';
export const colorGreyMedium = '#c5c5c59c';
export const colorGreyLight = '#c5c5cF';
export const colorGreyLightest = '#e9e9f0';
export const colorWhiteDarker = '#f7f7fc';

export const fontFamilySansSerif = 'Muli, sans-serif';

export const fontWeightLight = 300;
export const fontWeightBase = 400;
export const fontWeightMedium = 400;
export const fontWeightSemiBold = 600;
export const fontWeightBold = 700;

export const fontColorBase = '#26292A';
export const fontColorInverted = colorWhite;
export const fontColorLighter = '#464646';
export const fontColorLightest = '#919090';
export const fontColorLinkHover = '#007DFF';
export const fontColorDisabled = fontColorLightest;

export const textMaxWidth = '460px';
export const illustrationWidthRem = 8.5;
export const fontSizeRoot = '16px';
// These should be used and are affected by the theme's `fontSizeRoot` value
export const fontSizeXSmall = '0.7rem';
export const fontSizeSmall = '0.9rem';
export const fontSizeBase = '1rem';
export const fontSizeMedium = '1.1rem';
export const fontSizeLarge = '1.2rem';
export const fontSizeXLarge = '1.5rem';
export const lineHeightSizeXLarge = '1.7rem';
export const fontSizeBig = '1.9rem';
export const lineHeightSizeBig = '2.2rem';
export const largeIconFontSize = '2.3rem';

export const spaceXSmall = '3px';
export const spaceSmall = '0.2rem';
export const spaceNormal = '0.5rem';
export const spaceNormalLarge = '0.65rem';
export const spaceMedium = '0.8rem';
export const spaceLarge = '1.2rem';
export const spaceXLarge = '1.5rem';
export const spaceBig = '2rem';

export const transitionBase = 'all 0.2s ease-in-out';

export const colorSuccess = '#14ba89';
export const colorDanger = '#FF5656';
export const colorSuccessHover = darken(0.1, colorSuccess);

export const absoluteMaxColor = '#00DCAD';
export const absoluteMidColor = '#F0DECD';
export const absoluteMinColor = '#8121E8';
export const sentimentColors = [
  '#8121E8',
  '#B980DB',
  '#f2d8c0',
  '#78DDBD',
  '#00DCAD',
];

export const sentimentVerticalGradient = `linear-gradient(to right, ${absoluteMinColor} 5%, ${absoluteMidColor} 50%, ${absoluteMaxColor} 95%)`;

export const dimmerBackgroundColor = 'rgba(0,0,0,.7)';

export const borderRadius = '6px';

export const choiceBackground = '#F1F1FF';
export const choiceBackgroundOdd = '#f9f9f9';
export const choiceBackgroundHover = darken(0.1, choiceBackground);

export const baseBoxShadow = '0px 1px 2px 1px rgba(0, 0, 0, 0.12)';

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

const bwSiteLinkContainerHeightPx = 36;
export const bwSiteLinkContainerHeight = `${bwSiteLinkContainerHeightPx}px`;
export const appContainerHeight = `calc(100% - ${bwSiteLinkContainerHeightPx}px)`;
export const customerLogoHeight = '48px';

window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

const scrollbarTrackColor = 'rgba(245, 245, 245, 0.5)';
const scrollbarThumbColor = 'rgba(155, 155, 155, 0.5)';
export const thinScrollbarCss = css`
  &::-webkit-scrollbar {
    width: 4px;
    height: 2px;
  }
  &::-webkit-scrollbar-track {
    background: ${scrollbarTrackColor};
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${scrollbarThumbColor};
    border-radius: 20px;
    border: transparent;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: thin; /* "auto" or "thin"  */
  scrollbar-color: ${scrollbarThumbColor} ${scrollbarTrackColor}; /* thumb and track color */
`;

export const withHoverEffect = (styleString) => css`
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${styleString}
    }
  }
`;
