import axios from 'axios';

const api = axios.create({
  baseURL: window.location.origin,
  withCredentials: true,
});

/**
 * Return a query string 'key=value&..' from an object.
 * Does not include leading question mark.
 *
 */
function getQueryString(params) {
  // First step: we flatten key: values (list) into several key, value pairs.
  // Then format string and join.
  return Object.entries(params)
    .reduce((withDuplicates, pair) => {
      const [key, value] = pair;
      if (Array.isArray(value)) {
        const duplicated = [];
        for (const v of value) {
          duplicated.push([key, v]);
        }
        return withDuplicates.concat(duplicated);
      }
      withDuplicates.push([key, value]);
      return withDuplicates;
    }, [])
    .filter(([, value]) => value !== undefined && value !== '')
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
}

export { api, getQueryString };
