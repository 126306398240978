import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
};

export const uiSlice = createSlice({
  name: 'search',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setIsOpen: (state, { payload }) => {
      state.isOpen = payload;
    },
  },
});

export const { setIsOpen } = uiSlice.actions;

// Selectors
export const selectIsOpen = (state) => state.search.isOpen;

export default uiSlice.reducer;
