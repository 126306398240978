import React, { useEffect } from 'react';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { FaSearch } from 'react-icons/fa';

import styled from 'styled-components';

import { logEvent } from 'app/analytics';
import * as svars from 'style/variables';

import ClickableIcon from '../clickable/ClickableIcon';
import TextInput from './TextInput';

const TextInputDiv = styled(TextInput).attrs({ as: 'div' })`
  transition: ${svars.transitionBase};
  flex-grow: 1;
  display: inline-flex;
  align-items: center;
  padding-bottom: 0.3rem;
  margin: ${svars.spaceSmall};
  background: ${({ theme: { input } }) => input.background};
  border: ${({ theme: { input }, disabled }) =>
    `${input.borderWidth} solid ${input.borderColor}`};
  border-radius: ${({ theme: { input } }) => input.borderRadius};
  box-shadow: ${({ theme: { input } }) => input.boxShadow};
  & svg {
    fill: ${({ theme, disabled }) =>
      disabled ? svars.fontColorDisabled : theme.fontColorLight};
  }
  &:hover {
    border-color: ${({ theme: { input } }) => input.hover.borderColor};
  }
  &:focus-within {
    border-color: ${({ theme: { input } }) => input.focus.borderColor};
    box-shadow: ${({ theme: { input } }) => input.focus.boxShadow};
    & svg {
      fill: ${({ theme, disabled }) =>
        disabled ? svars.fontColorDisabled : theme.primary};
      border-color: ${({ theme: { input } }) => input.focus.borderColor};
    }
  }
`;
const SearchBarInput = styled(TextInput)`
  outline: none;
  border: none;
  width: 100%;
  padding: ${svars.spaceSmall};
  border-radius: ${({ theme: { input } }) => input.borderRadius};
  box-shadow: ${({ theme }) => theme.boxShadow};

  &:focus-within {
    & + span {
      color: ${({ theme, disabled }) =>
        disabled ? svars.fontColorDisabled : theme.primary};
    }
    & svg {
      color: ${({ disabled, theme }) =>
        disabled ? svars.fontColorDisabled : theme.primary};
    }
  }
  &::-webkit-search-cancel-button {
    position: relative;
    font-size: ${svars.fontSizeLarge};
    background: ${({ theme }) => theme.primary};
    cursor: pointer;
  }
`;

TextInput.defaultProps = { disabled: false };

const SearchBar = (
  {
    icon,
    left,
    fluid,
    style,
    containerStyle,
    onFocus,
    onChange,
    showBackButton,
    goBack,
    ...props
  },
  ref
) => {
  useEffect(() => {
    if (
      showBackButton &&
      ref.current &&
      document.activeElement !== ref.current
    ) {
      ref.current.focus();
    }
  }, [showBackButton, ref, props.value]);
  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        position: 'relative',
        width: fluid ? '100%' : 'inherit',
        marginBottom: svars.spaceNormal,
        ...containerStyle,
      }}
    >
      <TextInputDiv>
        {showBackButton ? (
          <ClickableIcon
            gaCategory="Search"
            gaAction="close search (arrow)"
            icon={<BiLeftArrowAlt />}
            key="icon"
            style={{
              cursor: 'pointer',
              margin: 0,
              marginRight: svars.spaceNormal,
            }}
            onClick={(event) => {
              event.stopPropagation();
              goBack();
            }}
          />
        ) : (
          <FaSearch
            style={{
              fontSize: svars.fontSizeXLarge,
              marginRight: svars.spaceNormal,
            }}
          />
        )}

        <SearchBarInput
          style={{
            boxSizing: 'border-box',
            ...style,
          }}
          ref={ref}
          type="search"
          onFocus={(event) => {
            logEvent({ category: 'Search', action: 'focus' });
            onFocus(event);
          }}
          onChange={onChange}
          {...props}
        />
      </TextInputDiv>
    </div>
  );
};

// SearchBar.propTypes = {
//   icon: PropTypes.node,
//   style: PropTypes.object,
//   containerStyle: PropTypes.object,
//   fluid: PropTypes.bool,
// };
// SearchBar.defaultProps = {
//   fluid: false,
//   icon: null,
//   style: {},
//   containerStyle: {},
// };

const forwardedRef = React.forwardRef(SearchBar);
export default forwardedRef;
