import { BiHelpCircle } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import {
  closeHelpModal,
  selectShowHelpModalTabIndex,
  toggleConfidentialityPolicy,
  toggleFaq,
  toggleUserTerms,
} from 'store/ui';

import Accordion from 'components/ui/Accordion';
import Modal from 'components/ui/Modal';
import { Tab, Tabs } from 'components/ui/Tabs';
import Button from 'components/ui/clickable/Button';
import commonConfigurationService from 'services/configuration.service';

const StyledIframe = styled.iframe`
  margin: 0 auto;
  display: flex;
  border: none;
`;

const HelpModal = () => {
  const dispatch = useDispatch();
  const { faqItems } = commonConfigurationService;
  const showHelpModalTabIndex = useSelector(selectShowHelpModalTabIndex);
  const handleClick = (e) => {
    const index = parseInt(e.target.id, 0);
    if (index === 1) {
      dispatch(toggleFaq());
    } else if (index === 2) {
      dispatch(toggleConfidentialityPolicy());
    } else if (index === 3) {
      dispatch(toggleUserTerms());
    } else {
      dispatch(closeHelpModal());
    }
  };
  const {
    language,
    wording: { commons },
    confidentiality: { organizationEmail, organizationPolicyUrl },
  } = commonConfigurationService;
  let url = '/confidentiality.html';
  let termsUrl = '/user-terms.html';
  const routeParams = [];
  if (organizationEmail) {
    url = '/confidentiality-with-email.html';
    routeParams.push(`dpo_email=${organizationEmail}`);
  }
  if (organizationPolicyUrl) {
    if (language === 'en') {
      url = '/confidentiality-with-email-and-policy-url-en.html';
      termsUrl = '/user-terms-en.html';
    } else if (language === 'zh') {
      url = '/confidentiality-with-email-and-policy-url-zhcn.html';
      termsUrl = '/user-terms-zhcn.html';
    } else {
      url = '/confidentiality-with-email-and-policy-url.html';
      termsUrl = '/user-terms.html';
    }
    routeParams.push(`organizationPolicyUrl=${organizationPolicyUrl}`);
  }
  const policyUrl = `${url}?${routeParams.join('&')}`;
  const closeModal = () => dispatch(closeHelpModal(null));
  return (
    <Modal
      icon={<BiHelpCircle />}
      isOpen={!!showHelpModalTabIndex}
      hide={closeModal}
      header={commons.helpTitle}
      actions={
        <Button
          secondary
          onClick={closeModal}
          data-testid="feedback-close-modal"
        >
          {commons.close}
        </Button>
      }
    >
      <Tabs>
        {faqItems?.length ? (
          <Tab
            onClick={handleClick}
            active={showHelpModalTabIndex === 1}
            id={1}
            data-testid="feedback-modal-faq-tab"
          >
            <span>{commons.faq}</span>
          </Tab>
        ) : null}

        <Tab
          onClick={handleClick}
          active={showHelpModalTabIndex === 2}
          id={2}
          data-testid="feedback-modal-privacy-policy-tab"
        >
          <span>{commons.privacyPolicy}</span>
        </Tab>
        <Tab
          onClick={handleClick}
          active={showHelpModalTabIndex === 3}
          id={3}
          data-testid="feedback-modal-terms-of-use-tab"
        >
          <span>{commons.cgu}</span>
        </Tab>
      </Tabs>
      <>
        {faqItems?.length ? (
          <Tab.Content
            style={{ height: '62vh', overflowY: 'auto' }}
            active={showHelpModalTabIndex === 1}
          >
            <Accordion
              items={faqItems}
              gaCategory="FAQ"
              gaAction="Toggle element"
              data-testid="feedback-modal-accordion"
            />
          </Tab.Content>
        ) : null}
        <Tab.Content
          style={{ height: '62vh', overflowY: 'auto' }}
          active={showHelpModalTabIndex === 2}
        >
          <StyledIframe
            frameborder="0"
            width="100%"
            height="100%"
            src={policyUrl}
          />
        </Tab.Content>
        <Tab.Content
          style={{ height: '62vh', overflowY: 'auto', padding: '0' }}
          active={showHelpModalTabIndex === 3}
        >
          <StyledIframe
            frameborder="0"
            width="100%"
            height="100%"
            src={termsUrl}
          />
        </Tab.Content>
      </>
    </Modal>
  );
};

export default HelpModal;
