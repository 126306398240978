import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  publishReview,
  selectLoading,
  selectPublishDisabled,
  selectSentimentRangeValue,
  selectText,
  setSentimentRangeValue,
  setText,
} from 'store/review';

import Divider from 'components/ui/Divider';
import { H5 } from 'components/ui/Header';
import Button from 'components/ui/clickable/Button';
import Link from 'components/ui/clickable/Link';
import TextArea from 'components/ui/input/TextArea';
import feedbackCampaignService from 'services/feedbackCampaign.service';
import * as svars from 'style/variables';
import { pushWithSearchQuery } from 'helpers';
import IdentificationForm from 'components/IdentificationForm';
import EmojiSentimentRange from 'components/ui/input/EmojiSentimentRange';
const ScrollableContainer = styled.div`
  overflow: clip auto;
  display: flex;
  flex-direction: column;
  ${svars.thinScrollbarCss}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${svars.spaceNormalLarge} ${svars.spaceNormal} 0 ${svars.spaceNormal};
  flex-grow: 1;
`;

const ActionContainer = styled.div`
  padding: ${svars.spaceNormal};
  margin-top: auto;
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const TextSentimentFeedback = ({ showConfidentilityPolicy }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { category: conceptId } = useParams();
  const goToPublished = () => pushWithSearchQuery('/published', history);

  const text = useSelector(selectText);
  const publishDisabled = useSelector(selectPublishDisabled);
  const sentimentRangeValue = useSelector(selectSentimentRangeValue);
  const {
    satisfactionTagSetItems,
    wording: { feedback },
  } = feedbackCampaignService;
  const loading = useSelector(selectLoading);
  if (!satisfactionTagSetItems) return null;
  return (
    <>
      <ScrollableContainer>
        <Container>
          <H5 style={{ paddingBottom: svars.spaceSmall }}>
            {feedback.satisfactionLevelQuestion}
          </H5>
          <EmojiSentimentRange
            options={satisfactionTagSetItems}
            selected={sentimentRangeValue}
            onSelect={(value) =>
              !loading &&
              dispatch(
                setSentimentRangeValue(
                  value === sentimentRangeValue ? null : value
                )
              )
            }
          />
          <H5>{feedback.openTextQuestion}</H5>
          <TextArea
            fluid
            style={{ marginBottom: svars.spaceLarge, minHeight: '6rem' }}
            placeholder={feedback.openTextPlaceholder}
            rows={3}
            value={text}
            onChange={({ target: { value } }) =>
              !loading && dispatch(setText(value))
            }
            data-testid="feedback-text-area"
          />
        </Container>
        <IdentificationForm />
      </ScrollableContainer>
      <Divider />

      <ActionContainer>
        {publishDisabled ? (
          <H5
            italic
            info
            style={{
              fontSize: svars.fontSizeSmall,
              paddingRight: svars.spaceMedium,
              paddingBottom: 0,
            }}
          >
            {feedback.emptyHelper}
          </H5>
        ) : (
          <H5
            italic
            info
            style={{ paddingRight: svars.spaceMedium, paddingBottom: 0 }}
          >
            {feedback.privacyNoticePrefix}{' '}
            <Link
              onClick={showConfidentilityPolicy}
              data-testid="feedback-form-privacy-link"
            >
              {feedback.privacyPolicy}
            </Link>
            .
          </H5>
        )}
        <Button
          gaCategory="Feedback"
          gaAction="publish"
          gaLabel={conceptId}
          disabled={publishDisabled}
          loading={loading}
          onClick={() =>
            !loading &&
            dispatch(
              publishReview({
                conceptId,
                searchQuery: history.location.search,
              })
            ).then(goToPublished)
          }
          data-testid="feedback-send-feedback-button"
        >
          {feedback.ctaText}
        </Button>
      </ActionContainer>
    </>
  );
};

TextSentimentFeedback.propTypes = {
  showConfidentilityPolicy: PropTypes.func.isRequired,
};

export default TextSentimentFeedback;
