import * as svars from 'style/variables';

import makeTheme from './constructor';

const primary = '#007DDF';

export const hoverBoxShadow = '0px 0px 4px 1px rgb(0, 125, 255, 0.2)';

export const choiceBorderColor = 'rgba(0, 125, 255, 0.5)';

const defaultTheme = makeTheme(
  'Default',
  primary,
  svars.fontColorBase,
  '#FFFFFF',
  false,
  {
    // appBackground: "linear-gradient(to right, #F2F2E4, #ffffff)",
    // appBackground: "linear-gradient(90deg, #f2f9ff, #f3ffff, #f9fff4, #fefff5)",
    appBackground:
      'radial-gradient(64.35% 64.35% at 50% 50%, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(23.41% 49.32% at 71.28% 77.41%, rgba(178, 255, 235, 0.6) 0%, rgba(129, 203, 232, 0.12) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(28.05% 59.09% at 83.18% 90.51%, rgba(179, 232, 255, 0.6) 0%, rgba(218, 255, 243, 0.6) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(35.23% 90.62% at 23.41% 64.03%, rgba(246, 203, 255, 0.8) 0%, rgba(142, 223, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(36.48% 58.44% at 77.73% 50%, #F6CBFF 0%, rgba(142, 223, 255, 0) 100%)',
    border: '2px solid transparent',
    borderRadius: '6px',
    hoverBoxShadow,

    segmentBackground: '#FFFFFF',

    choiceBackground: '#FFFFFF',
    choiceBackgroundOdd: '#FFFFFF',
    choiceBorderColor,
    // choiceSecondaryBackground: primary,
    choiceBoxShadow: svars.baseBoxShadow,
    choiceHoverTextColor: '#FFFFFF',

    tagBackground: '#f1f1f1', // previous : #E9E9E9",
    tagBackgroundColorHover: '#d1d1d1',
    tagBorderColor: 'transparent',
    tagBackgroundColorSecondary: primary,
    tagSecondaryTextColor: '#FFFFFF',

    searchBarBoxShadow: `0px 0px 3px ${primary}`,
    searchBarBorderColor: 'transparent',

    inputBorderColor: svars.fontColorBase,
    inputBorderColorHover: '#6CB8EC',
    inputBorderColorFocus: primary,

    iconBackground: '#E9E9E9',
  }
);

export default defaultTheme;
