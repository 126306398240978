import { renderToStaticMarkup } from 'react-dom/server';

import { sentimentColors } from 'style/variables';

const BlobBackground = (colors, opacity) => (
  <svg
    opacity={opacity}
    width="1000"
    height="993"
    viewBox="0 0 1000 993"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M973.108 116.5C947.128 177 928.628 152.5 915.108 152.5C883.075 152.5 898.107 172.5 873.607 168C834.128 160.745 867.133 69.5 912.627 69.5C958.122 69.5 981.911 96 973.108 116.5Z"
      fill={colors[1]}
    />
    <rect width="1000" height="993" fill="white" />
    <path
      d="M495.308 231.782C546.729 263.82 550.995 457.359 368.541 453.956C241.07 451.578 166.785 246.933 268.888 205.222C309.622 188.596 346.377 241.723 362.471 241.723C447.273 241.723 436.745 195.294 495.308 231.782Z"
      fill={colors[3]}
    />
    <path
      d="M243 467.066C243 513.838 183.851 553 130.952 553C78.0522 553 43 524.424 43 477.652C43 430.88 122.631 383 175.53 383C228.43 383 243 420.294 243 467.066Z"
      fill={colors[2]}
    />
    <path
      d="M869.857 846C927.084 846 975 882.672 975 915.47C975 948.268 940.037 970 882.81 970C825.582 970 767 920.629 767 887.831C767 855.034 812.63 846 869.857 846Z"
      fill={colors[2]}
    />
    <path
      d="M563 621.495C563 710.432 526.422 804 450.759 804C375.096 804 289 734.97 289 646.034C289 557.097 350.337 485 426 485C501.663 485 563 532.559 563 621.495Z"
      fill={colors[4]}
    />
    <path
      d="M260 711.034C260 764.631 224.339 791 156.295 791C88.2515 791 26 756.094 26 702.496C26 648.899 63.4378 607 131.482 607C199.526 607 260 657.437 260 711.034Z"
      fill={colors[1]}
    />
    <path
      d="M23.4497 155.811C38.19 72.2144 88.9845 23.2094 143.472 32.8171C197.96 42.4247 230.573 106.137 215.833 189.733C201.092 273.33 165.135 348.778 110.647 339.171C56.1591 329.563 8.70936 239.407 23.4497 155.811Z"
      fill={colors[4]}
    />
    <path
      d="M176.995 916.498C176.995 946.874 158.833 958.5 131.495 958.5C104.157 958.5 86 937.376 86 907C86 876.624 104.157 857 131.495 857C158.833 857 176.995 886.123 176.995 916.498Z"
      fill={colors[3]}
    />
    <path
      d="M762 805.075C762 881.568 697.687 958 637.62 958C577.553 958 552 923.691 552 847.198C552 770.706 574.562 681 650.347 681C710.414 681 762 728.582 762 805.075Z"
      fill={colors[3]}
    />
    <path
      d="M799 391.647C799 476.153 703.646 553 631.796 553C559.949 553 579.517 511.854 579.518 468.137C579.519 428.276 472.541 284.285 680.431 259.835C751.841 251.437 799 307.142 799 391.647Z"
      fill={colors[1]}
    />
    <path
      d="M938.245 676.137C938.245 752.046 970.104 791 878.593 791C810.082 791 762 752.045 762 676.136C762 600.226 841.242 493.463 938.245 540.608C1020.94 580.802 938.245 621.563 938.245 676.137Z"
      fill={colors[0]}
    />
    <path
      d="M461.162 89.1144C499.3 105.167 552 92.4908 552 135.552C552 197.473 470.889 171.667 448.7 171.667C373.816 171.667 271.914 203.721 298.24 89.1101C329.329 -46.2379 430.653 76.2731 461.162 89.1144Z"
      fill={colors[0]}
    />
    <path
      d="M923.465 102.284C1018.07 200.225 951.709 259 848.003 259C744.297 259 619 207.294 619 143.519C619 79.7451 645.31 44.0265 693.57 31.6543C786.068 7.93405 886.612 64.1314 923.465 102.284Z"
      fill={colors[2]}
    />
    <path
      d="M457.58 916.359C477.784 1000.77 403.46 979.047 365.931 957.449C335.152 939.736 342.857 982.915 267.809 957.449C189.157 930.76 200.528 847.482 267.797 800.418C309.652 771.138 432.175 810.223 457.58 916.359Z"
      fill={colors[1]}
    />
    <path
      d="M942.041 388.477C966.943 427.362 970.51 430.108 968.54 453.385C964.974 495.534 891.59 495.542 862.036 453.388C813.628 384.343 862.043 319.562 875.796 309.52C928.285 271.19 923.19 359.041 942.041 388.477Z"
      fill={colors[4]}
    />
  </svg>
);

const yellowBlobBackground = encodeURIComponent(
  renderToStaticMarkup(
    BlobBackground(['#ECE5C1', '#F7E273', '#FFDD88', '#FFCB54', '#FDB40D'], 1)
  )
);

const sentimentBlobBackground = encodeURIComponent(
  renderToStaticMarkup(BlobBackground(sentimentColors, 0.4))
);
const chnoBlobBackground = encodeURIComponent(
  renderToStaticMarkup(
    BlobBackground(['#2279b0', '#0077c0', '#0075c4', '#ffe970', '#fcde35'], 0.7)
  )
);

export { yellowBlobBackground, sentimentBlobBackground, chnoBlobBackground };
export default BlobBackground;
