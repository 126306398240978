function getMostContrastedColor(color, fontColorIfLight, fontColorIfDark) {
  // Variables for red, green, blue values
  let r, g, b, hsp;
  let transformedColor = color;

  // Check the format of the color, HEX or RGB?
  if (transformedColor.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    transformedColor = transformedColor.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = transformedColor[1];
    g = transformedColor[2];
    b = transformedColor[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    transformedColor = +(
      '0x' +
      transformedColor
        .slice(1)
        .replace(transformedColor.length < 5 && /./g, '$&$&')
    );

    r = transformedColor >> 16;
    g = (transformedColor >> 8) & 255;
    b = transformedColor & 255;
  }

  // HSP equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  // Value should theoretically be 127.5, but is shifted to 130 to have light color on our accent color
  if (hsp > 130) {
    return fontColorIfLight;
  } else {
    return fontColorIfDark;
  }
}

export default getMostContrastedColor;
