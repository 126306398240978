import { accentFold } from 'helpers';

export const identificationFormTypes = {
  INPUT: 'InputFormElement',
  MULTIPLE_CHOICE: 'MultipleChoiceFormElement',
};

// Sort method to sort the list of objects by their position attribute
const compareObjectsPositions = (a, b) => {
  if (a.position < b.position) {
    return -1;
  }
  if (a.position > b.position) {
    return 1;
  }
  return 0;
};

const enrichIdentificationForm = (identificationForm, tagSets) =>
  identificationForm
    .sort(compareObjectsPositions)
    .map((identificationFormElement) => {
      let enrichedIdentificationForm = {
        label: identificationFormElement.label,
        name: identificationFormElement.name,
        placeholder: identificationFormElement.placeholder || '',
        mandatory: identificationFormElement.mandatory,
      };
      switch (identificationFormElement.form_type) {
        case identificationFormTypes.INPUT: {
          enrichedIdentificationForm = {
            ...enrichedIdentificationForm,
            type: identificationFormTypes.INPUT,

            icon: identificationFormElement.icon,
            id: identificationFormElement.id,
          };
          break;
        }
        case identificationFormTypes.MULTIPLE_CHOICE: {
          enrichedIdentificationForm = {
            ...enrichedIdentificationForm,
            type: identificationFormTypes.MULTIPLE_CHOICE,
            searchable: identificationFormElement.searchable,
            dropdown: identificationFormElement.dropdown,
            alwaysUp:
              identificationForm.length - identificationFormElement.position <
              2,
            id: identificationFormElement.id,
            tagSetId: identificationFormElement.tag_set.id,
            items: tagSets
              .filter(
                (tagSet) => tagSet.id === identificationFormElement.tag_set.id
              )[0]
              .items.map((item) => {
                return {
                  key: item.display_name,
                  label: item.display_name,
                  value: item.id,
                  id: item.id,
                  searchText: accentFold(
                    [item.display_name, ...(item.keywords || [])].join('@ ')
                  ),
                };
              }),
          };
          break;
        }
        default: {
          break;
        }
      }
      return enrichedIdentificationForm;
    });

// We use a service because it is a simplified redux store and the data stocked here is static
// during one session (It is only loaded at the SPA loading)
class feedbackCampaignService {
  constructor() {
    this.wording = {
      home: {},
      search: {},
      published: {},
      identificationWording: {},
      feedback: {},
    };
    this.identificationForm = [];
    this.satisfactionTagSetItems = [];
  }
  get dump() {
    return JSON.stringify(this);
  }

  load({
    configuration_ui,
    satisfaction_tag_set: { id: satisfactionTagSetId },
    identification_form: identificationForm,
    tag_sets: tagSets,
  }) {
    this.expandedForm = configuration_ui.expanded_form;
    this.wording = configuration_ui.wording;
    if (configuration_ui?.wording?.search) {
      this.wording.search = {
        ...configuration_ui.wording.search,
        keyword_examples:
          typeof configuration_ui.wording.search.keyword_examples === 'string'
            ? configuration_ui.wording.search.keyword_examples
                .split(',')
                .map((text) => text && text.trim())
            : configuration_ui.wording.search.keyword_examples,
      };
    }
    if (configuration_ui.wording.identification_wording) {
      this.wording.identificationWording =
        configuration_ui.wording.identification_wording;
    }
    this.wording.identificationWording =
      configuration_ui.wording?.identification_wording || {};
    this.wording.conceptHighlight =
      configuration_ui.wording?.concept_highlight || {};
    this.satisfactionTagSetId = satisfactionTagSetId;
    this.satisfactionTagSetItems = tagSets.find(
      ({ id }) => satisfactionTagSetId === id
    ).items;
    if (identificationForm) {
      this.identificationForm = enrichIdentificationForm(
        identificationForm,
        tagSets
      );
    }
  }
}

const configurationUiService = new feedbackCampaignService();

export default configurationUiService;
