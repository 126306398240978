import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as svars from 'style/variables';

const Progress = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.primary};
  transition: width 0.5s;
  width: ${({ progress }) => (progress || 0) * 100}%;
`;

const ProgressBarContainer = styled.div`
  height: 5px;
  width: 100%;
  background-color: ${svars.colorGreyMedium};
`;

const ProgressBar = ({ progress, style, children }) => {
  return (
    <ProgressBarContainer style={style}>
      <Progress progress={progress}>{children}</Progress>
    </ProgressBarContainer>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  style: PropTypes.object,
  children: PropTypes.node,
};

ProgressBar.defaultProps = {
  style: {},
  children: null,
};

export default ProgressBar;
