import React from 'react';
import { FaTimes } from 'react-icons/fa';

import styled from 'styled-components';

import FadeInOut from 'components/ui/animation/FadeInOut';
import BwCopyright from 'components/ui/BwCopyright';
import { H2 } from 'components/ui/Header';
import MagnifyingGlass from 'components/ui/MagnifyingGlass';
import Segment from 'components/ui/Segment';

import * as svars from 'style/variables';

const AppContainer = styled.div`
  height: ${svars.appContainerHeight};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: hidden;
`;

const RouteNotFoundPage = () => {
  return (
    <React.Fragment>
      <AppContainer>
        <FadeInOut show>
          <Segment
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: svars.spaceLarge,
            }}
          >
            <MagnifyingGlass
              icon={<FaTimes style={{ color: svars.colorDanger }} />}
            />
            <H2 centered>
              Le lien que vous avez suivi n&apos;existe pas ou a été archivé
            </H2>
            <span style={{ margin: `0 ${svars.spaceMedium}` }}>
              <p>Nous sommes navrés pour ce désagrément</p>
            </span>
          </Segment>
        </FadeInOut>
      </AppContainer>
      <BwCopyright />
    </React.Fragment>
  );
};

export default RouteNotFoundPage;
